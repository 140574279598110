export const NAME: string = 'Footer'

export type FooterTheme = {
    showNewsLetter?: boolean
    showSocialMediaLinks?: boolean
    showSocialMediaInCountry?: CountryCode[]
}

export enum CountryCode {
    SE = 'se',
}
