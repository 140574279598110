import PropTypes from 'prop-types';
import React from 'react';
import logger from 'score/logSvc';
// Function that uised to remove umlats
import _unescape from './decode-ent';

const log = logger.getLogger('CleanText');

export const getRefinedValue = (value, allowedTags) => {
  let tagsRegex = '';
  allowedTags.map((tag) => {
    if (tag === 'br') {
      tagsRegex += '(?!br/)(?!br)';
    } else {
      tagsRegex = tagsRegex + '(?!' + tag + ')(?!/' + tag + ')';
    }
    return tagsRegex;
  });
  const replaceString = new RegExp('<(' + tagsRegex + 's*/?)[^>]+>', 'g');
  return value.replace(replaceString, '');
};

export default class CleanText extends React.Component {
  /**
   * propTypes
   * @property {string} value
   */
  static get propTypes() {
    return {
      value: PropTypes.string,
      onlyText: PropTypes.bool,
      allowedTags: PropTypes.array,
    };
  }

  render() {
    let refinedValue = this.props.value ? this.props.value : '';
    if (this.props.allowedTags && this.props.allowedTags.length > 0) {
      refinedValue = getRefinedValue(refinedValue, this.props.allowedTags);
    }
    const valueWithoutUmlats = this.props.value ? _unescape(refinedValue) : '';
    const cleanValues = valueWithoutUmlats.split(/<p.*?>(.*?)<\/p>/).filter(Boolean);
    // Disabling index warning becasue of the simple nature of the items in the array
    if (this.props.onlyText === true) {
      /* eslint-disable */
      return cleanValues ? cleanValues.map((para, i) => <span key={'row' + i} className={this.props.className} dangerouslySetInnerHTML={{ __html: para }} />) : null;
      /* eslint-enable */
    }
    return valueWithoutUmlats;
  }
}
CleanText.defaultProps = {
  value: '',
  onlyText: true,
};
