/**
 * Locales supported for postalCode formatting. Supported locales are: da, sv, no, de, de_AT, de_CH, fr_CH, fr, fr_BE,
 * nl_BE, nl, fi, sv_FI, it, es, es_IE, en.
 */

const locales = {
  da: {
    regex: /(DK|FO|GL)?[-]?(\d{3,4})/,

    formatFunc: (matcher, p1, p2, offset, string) => {
      let g1 = p1;
      let g2 = p2;
      if (g2.length === 3 || (g2.length === 4 && g2.startsWith('0'))) {
        g1 = 'FO';
      } else if (g2.length === 4 && g2.startsWith('39')) {
        g1 = 'GL';
      }
      if (!g1) {
        g1 = 'DK';
      }
      if (g2.length === 3) {
        g2 = '0' + g2;
      }
      return `${g1}-${g2}`;
    },
  },
  sv: {
    regex: /(\d{3})\s?(\d{2})/,
    formatFunc: '$1 $2',
  },
  default: {
    regex: /.*/,
    formatFunc: (_matcher, _offset, string) => string,
  },
};

/**
 * Class for formatting postalCodes. This should not be inlcude directly but used via the locale service.
 */
export class PostalCodeFormatter {
  format(postalCode, inputLocale) {
    let locale = inputLocale;
    if (locale === undefined) {
      locale = 'sv';
    }
    const code = postalCode.trim();
    const filteredConf = locales[locale] ? locales[locale] : locales.default;
    if (code && filteredConf.regex.test(code)) {
      return code.replace(filteredConf.regex, filteredConf.formatFunc);
    }
    return code;
  }
}

const formatter = new PostalCodeFormatter();
export default formatter;
