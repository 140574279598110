import { combineReducers } from 'redux';
import moduleToReducer from 'core/redux/moduleToReducer';
import logger from 'score/logSvc';
import { reducers as coreReducers } from '@spa-core/store';

const log = logger.getLogger('redux/reducer');

/**
 * This file contains the reducers for the redux state and is used by the module loader in order to
 * retain and setup the reducers that redux craves without burdening the developer with that boilerplate
 * code.
 */
const reducers = { modules: {} };
const mod = {};

/**
 * Function used to add an existing reducer to the redux store. This is not using any
 * of the luxeries that the module loader provides but provides a way of adding a raw
 * reducer.
 */
export const registerReducer = (path, fun) => {
  reducers[path] = fun;
  // log.info('Registering reducer, now: ', reducers, combineReducers(reducers));
};

/**
 * This function is by the module loader to register a reducer for a module.
 */
export const registerModule = (path, module) => {
  mod[path] = moduleToReducer(module);
  reducers.modules = combineReducers(mod);
};

/**
 * When utilizing lazy loading you can encounter parts of the state which does not match any known module.
 * The reason for this is that this mysterious module has not yet been loaded. In order not to mess upp the
 * state a temporary reducer can be used to be take the responsibilty for that state without changing it.
 * This reducer is used for that.
 */
const defaultReducer = (state) => { // eslint-disable-line
  if (state) {
    return state;
  }
  return {};
};

/**
 * Used in application start in order to get the root reducer for the store.
 */
export const getReducer = (_iState) => {
  /*
  if (Object.keys(mod).length === 0) {
    return defaultReducer;
  }
  if (iState) {
    each(iState.modules, (_module, name) => {
      if (!mod[name]) {
        mod[name] = defaultReducer;
      }
    });
  }
  reducers.modules = combineReducers(mod);
  */
  reducers.reducers = combineReducers({
    ...coreReducers,
  });
  return combineReducers(reducers);
};
