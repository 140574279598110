import 'core/sass/global-styles.scss';
import 'site/site-styles.scss';
import loadable from '@loadable/component';
import { actions as _actions, AppRouting, inject, mountFun, mutations } from 'core/common-app-mod.jsx';
import { mapItems, mapService, setupInitialState, setupMutations, superConnect } from 'core/util/module-utils';
import AppUtil, { navigation } from 'eco/AppUtil/AppUtilMod.jsx';
import GlobalMessagesTs from '@spa-ec/components/GlobalMessages/GlobalMessages';
import GlobalMessages from 'eco/GlobalMessages/GlobalMessagesMod.jsx';
import SeoLinks from '@spa-ec/components/SEOLinks/NavLinks';
import cloneDeep from 'fast-clone';
import Footer from 'FOOTER';
import getComponentByType from 'GET_COMPONENT_BY_TYPE';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import logger from 'score/logSvc';
import { getters as navigationSvcGetters, svcData as navData } from 'seco/navigationSvc/navigationSvcMod';
import { ThemeProvider } from 'styled-components';
import { getThemeFactory } from '@ic-theme';
import loadWithSSRFallback from 'ui/Loader';
import browserSvc from 'score/browserSvc';
import { updatePageContextWithLanguage } from './util/smartedit';
import { ErrorBoundary } from 'react-error-boundary';
import Error from '@spa-ec/components/Error/Error';
import { NAME as appReducerName } from '@spa-core/store/app/constants';
import { getReducer } from '@spa-core/legacy-adapter/utils';

const Header = loadWithSSRFallback(() => import('HEADER'), 'Header', {
  hydrate: 'always',
  force: true,
});

const DebugInfo = loadable(() => import('eco/DebugInfo/DebugInfoMod.jsx'));
const UsertypeDivider = loadable(() => import('USER_TYPE_DIVIDER'));
const th = getThemeFactory('App', {});

// Setup module multi/single instance name etc
const multipleInstances = false;
const name = 'App';

// Paranoid way of checking if value exists in sessionConf provided in the document
// Modules data, this is the initial data for an instance
const initialState = {
  upd: 1,
  deviceType: 'desktop',
  advnceEditFlag: false,
};

const log = logger.getLogger(name);
const conf = { multipleInstances, name, initialState };

// Inject this applications con into the common functions
inject(conf, getComponentByType);
// ################# GETTERS  #################
export const getters = (state, ownProps) => {
  // Leave this line fetches ta state variable depending on the module is using instances or not
  const instance = cloneDeep(mapItems(state, conf, ownProps.iid));
  mapService(navigationSvcGetters(state, ownProps), instance);

  /**
   * Refactoring mapping
   */
  const sessionConfigState = state.reducers[appReducerName]?.sessionConfig;
  const sessionCampaignNumber = sessionConfigState?.campaignNumber;
  instance.sessionCampaignNumber = sessionCampaignNumber;
  const sessionCampaignPageId = sessionConfigState?.campaignNumber;
  instance.sessionCampaignPageId = sessionCampaignPageId;

  instance.isLoggedIn = sessionConfigState?.userLoggedIn;
  instance.internalSession = sessionConfigState?.internalSession;
  instance.sessionConfig = sessionConfigState?.sessionConfig || window.sessionConf;

  return instance;
};

// ################# ACTIONS  #################
export const actions = _actions;

// ################# MUTATIONS  #################

// ################# MODULE SETUP DON T TOUCH  #################
export const _module = {
  name,
  state: setupInitialState(initialState, conf),
  getters,
  actions,
  mutations: setupMutations(mutations, conf),
};

const parseBool = (val) => val === true || val === 'true';

/*
flowchart TD
  start --> check1{{isMixedMode == true}} --false --> HideButton
  check1 --true-->check2{{limitSwitchButtonForInternalUsage == true}}
  check2 --false-->ShowButton
  check2 --true-->check3{{internalSession == true}}
  check3 --true--> ShowButton
  check3 --false--> HideButton
*/
if (window.addEventListener) {
  window.addEventListener('message', handleMessage);
} else {
  window.attachEvent('onmessage', handleMessage);
}

function handleMessage(event) {
  /*   Receiving the event and fetching the page ID in 
  the local storage and routing the to the page url if the page ID is same in event with the local storage once
   */
  if (event.data.gatewayId === 'CROSS_FRAME_EVENT' && event.data.eventId === 'PAGE_CHANGE') {
    let dataFromParentIframe = event.data.data;
    if (dataFromParentIframe.catalogDescriptor) {
      browserSvc.localStorageSet('smarteditCatalogVersion', dataFromParentIframe.catalogDescriptor.catalogVersionUuid);
      browserSvc.localStorageSet('smarteditCatalogDescriptor', JSON.stringify(dataFromParentIframe.catalogDescriptor));
      const page =
        navData?.routes !== undefined ? navData?.routes?.filter((route) => route.pageId === dataFromParentIframe.pageId) : null;

      if (
        dataFromParentIframe.pageId &&
        dataFromParentIframe.pageId !== browserSvc.localStorageGet('smarteditHistoryPageID') &&
        page &&
        page.length > 0
      ) {
        navigation.navigateTo(page[0].url);
      }
    }
  }
  if (event.data.gatewayId === 'personalizationsmarteditContextServiceProxy' && event.data.eventId === 'setSeData') {
    let dataFromParentIframe = event.data.data.arguments[0];
    if (dataFromParentIframe.seConfigurationData) {
      browserSvc.localStorageSet('smarteditPreviewUrl', dataFromParentIframe.seConfigurationData.smarteditroot);
    }
  }
  const sessionConfigHandleMessage = getReducer(appReducerName).sessionConfig;
  if (
    event.data.gatewayId === 'heartBeatGateway' &&
    event.data.eventId === 'promiseAcknowledgement' &&
    sessionConfigHandleMessage.currentLanguageIsocode &&
    sessionStorage.getItem('fireLanguageChangeEvent') === 'true'
  ) {
    setTimeout(() => updatePageContextWithLanguage(sessionConfigHandleMessage.currentLanguageIsocode), 2000);
  }
}

const renderComponent = (componentId, componentType, parentId) => {
  // eslint-disable-next-line no-console
  console.debug('renderComponent args', componentId, componentType, parentId);
  // eslint-disable-next-line no-console, no-undef
  console.debug('arguments', arguments[0], arguments[1], arguments[2]);

  // Reload the Iframe
  if (document.getElementById('ySmartEditFrame')) {
    // eslint-disable-next-line no-console
    console.debug('Found iframe in documnt,reloading iframe');
    document.getElementById('ySmartEditFrame').contentWindow.location.reload(true);
  } else {
    // eslint-disable-next-line no-console
    console.debug('Did not find iframe in documnt,reloading window');
    window.location.reload(true);
  }
};

if (window.smartedit) {
  window.smartedit.renderComponent = renderComponent;
}

class App extends React.Component {
  componentDidMount() {
    const fetchSiteMapEntriesIfCampaignActiveInSession = this.props.sessionCampaignNumber;
    mountFun(() => this.props.fetchSitemap(fetchSiteMapEntriesIfCampaignActiveInSession), this.props);

    // Will not do anything if there is no campaign
    // this.props.handleCampaign(this.props.history);
    this.props.handleDeviceWidth(this.props.deviceType, th().size);
    this.props.handleSmarteditNavigation();
    this.props.checkForAdvancedEditMode();
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.isLoggedIn !== this.props.isLoggedIn) return true;
    // A device type switch should re-render all
    if (nextProps.deviceType !== this.props.deviceType) return true;
    if (nextProps.internalSession !== this.props.internalSession) return true;
    if (nextProps.footerNotice !== this.props.footerNotice) return true;
    if (nextProps.routes.length !== this.props.routes.length) return true;
    if (nextProps.campaignPageId !== this.props.campaignPageId) return true;

    return nextProps.sessionCampaignNumber !== this.props.sessionCampaignNumber;
  }

  render() {
    log.info('Rendering app, root level', navData.headerNodes.length);
    const isLoggedin = parseBool(this.props.isLoggedIn);
    let urlPrefix = this.props.sessionConfig.urlPrefix || '/se/sv';
    urlPrefix =
      urlPrefix.indexOf('https://') === 0
        ? urlPrefix.substring(this.props.sessionConfig.baseUrl.length, urlPrefix.length)
        : urlPrefix;
    const theme = th();

    return (
      <ThemeProvider theme={theme}>
        <BrowserRouter basename={urlPrefix}>
          <>
            <AppUtil />
            <Header SSRKey={'the-header'} showFavouriteIcon={'true'} showMyAccountIcon={'true'} key={'header'} {...this.props} />
            <div id="wrapper" className="e2e-wrapper mx-2 min-h-fit" key={'app-mod-wrapper'}>
              <ErrorBoundary FallbackComponent={(args) => <Error {...args} />}>
                <div id="page" key={'app-mod-wrapper-page'}>
                  <div id="content" key={'app-mod-wrapper-page-content'}>
                    <GlobalMessagesTs key={'app-mod-messages-ts'} />
                    <GlobalMessages key={'app-mod-messages'} />
                    <AppRouting {...this.props} isMobile={false} isLoggedIn={isLoggedin} key={'app-mod-routing'} />
                  </div>
                </div>
                {/* {sessionConf.siteName === 'inkClub' ? <UsertypeDivider {...this.props} /> : null} */}
                <UsertypeDivider {...this.props} />
              </ErrorBoundary>
            </div>
            <div className="e2e-wrapper z-[2] mt-20 md:mt-6">
              <Footer pageId={navData.routes && navData.routes['/'] ? navData.routes['/'].pageId : undefined} />
              <SeoLinks nodes={navData.headerNodes} />
            </div>
            <DebugInfo key={'debug-info'} internal={this.props.internalSession} />
            {/* <CrossSell /> */}
          </>
        </BrowserRouter>
      </ThemeProvider>
    );
  }
}

require("core/redux/reducer").registerModule(name, _module);require("@spa-core/redux/store").newModuleLoaded();
export default superConnect(_module, App);
