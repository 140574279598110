import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import logger from 'score/logSvc';
import { NAME as appReducerName } from '@spa-core/store/app/constants';
import { getReducer } from '@spa-core/legacy-adapter/utils';

const log = logger.getLogger('Link',{}); // eslint-disable-line

// const theme = getThemeFactory('Spinner', {});

class UILink extends React.Component {
  static get propTypes() {
    return {
      to: PropTypes.string,
      children: PropTypes.node,
      className: PropTypes.string,
      linkTitle: PropTypes.string,
      title: PropTypes.string,
      titleClassName: PropTypes.string,
      executeBeforeNavigation: PropTypes.func,
      external: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    };
  }

  /**
   * Render function for react, called very time there is state change.
   * @returns {Html} The rendered code
   */
  render() {
    const p = this.props;
    let url;
    let external = false;
    const sessionConfig = getReducer(appReducerName).sessionConfig;
    if (p.external === 'true' || p.external === true) {
      external = true;
    }
    let target;
    if (p.target === 'NEWWINDOW' || p.target === 'newWindow') {
      target = '_blank';
    }
    if (!external && p.to.indexOf('http') === 0) {
      if (sessionConfig.urlPrefix.indexOf('http') === 0) {
        url = p.to.replace(sessionConfig.urlPrefix, '');
      } else {
        url = p.to.replace(sessionConfig.baseUrl + sessionConfig.urlPrefix, '');
      }
    } else {
      url = p.to;
    }
    let stylingClassesForTitle = '';
    if (p.titleClassName) {
      stylingClassesForTitle = p.titleClassName;
    }
    return (
      <div
        data-ssr-key={p.SSRKey}
        data-smartedit-component-id={this.props.SSRKey}
        data-smartedit-component-uuid={this.props.elementUid}
        data-smartedit-catalog-version-uuid={this.props.contentSlotCatalogVersion}
        data-smartedit-component-type={this.props.componentParentType}
        className="smartEditComponent"
      >
        {url.indexOf('www') < 0 && !external ? (
          <Link
            title={p.title}
            className={p.className}
            target={target}
            to={url}
            onClick={() => {
              // Check if there is a function that needs to be executed before we navigate to the target location
              // This is only applicable in case of in page route navigation
              if (p.executeBeforeNavigation instanceof Function) {
                p.executeBeforeNavigation();
              }
            }}
          >
            {p.children}
            <div
              className={
                p.to.toLowerCase() === window.location.href.toLowerCase()
                  ? 'selected ' + stylingClassesForTitle
                  : stylingClassesForTitle
              }
            >
              {p.linkTitle ? p.linkTitle : ''}
            </div>
          </Link>
        ) : (
          <div>
            {url.indexOf('www') > 0 || url.indexOf('http://') === 0 || url.indexOf('https://') === 0 ? (
              <a title={p.title} href={url} target={target}>
                {p.linkTitle ? p.linkTitle : p.children}
              </a>
            ) : (
              <a title={p.title} href={url}>
                {p.children}
              </a>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(UILink);
