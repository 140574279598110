import { takeLatest, put, call, select } from 'redux-saga/effects'
import net from '@spa-core-js/services/networkSvc'
import { ActionTypes } from './constants'
import { SessionConfig } from '../app/interfaces'
import { selectSessionConfig } from '../utils'
import logger from '@spa-core-js/services/logSvc'
import { addRestCallFailed } from '../global-messages/actions'
import { HttpMethods } from '@spa-core/constants/HttpMethods'

const log = logger.getLogger('HistoricalPrices')

export function* fetchHistoricalLowPrice({ payload }: any) {
    const { productCode } = payload
    const sessionConfig: SessionConfig = yield select(selectSessionConfig)
    const url: string = sessionConfig.urlPrefix + `/price/lowest/${productCode}`
    try {
        const results = yield call(() => net.get(url, { cache: 'none ' }))
        yield put({
            type: ActionTypes.FETCHED_HISTORICAL_LOW_PRICE,
            payload: {
                historicalLowPrice: results,
                productCode,
            },
        })
    } catch (error) {
        yield put(
            addRestCallFailed({
                url,
                method: HttpMethods.GET,
                error,
            }),
        )
        log.error('Error occured in fetching the historical prices', error)
    }
}

export const watchers = [takeLatest(ActionTypes.FETCH_HISTORICAL_LOW_PRICE, fetchHistoricalLowPrice)]
