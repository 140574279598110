import getStore, { getFromLocalStore } from '@spa-core/redux/store';
import logger from 'score/logSvc';
import debounce from 'lodash/debounce';
import cleanKey from 'core/util/cleanKey';

const log = logger.getLogger('extractStyles');

export const isPuppeteer = typeof navigator === 'undefined' ? false : navigator.userAgent.indexOf('HeadlessChrome') >= 0;
export const isVTT = typeof navigator === 'undefined' ? false : navigator.userAgent.indexOf('(VTT)') >= 0;

export const injectStyles = (css) => {
  // Clear out the old
  const head = document.head || document.getElementsByTagName('head')[0];
  const style = document.createElement('style');
  style.id = 'initial-styles';

  style.type = 'text/css';
  if (style.styleSheet) {
    // This is required for IE8 and below.
    style.styleSheet.cssText = css;
  } else {
    style.appendChild(document.createTextNode(css));
  }
  head.appendChild(style);
};

let scriptTag = typeof document === 'undefined' ? undefined : document.getElementById('initial-state');
export const rawInjectState = () => {
  // clear out the old script tag before creating a new one
  // this is required for the old tag to execute
  if (!scriptTag) {
    // comment
  } else {
    document.head.removeChild(scriptTag);
  }
  const head = document.head || document.getElementsByTagName('head')[0];
  scriptTag = document.createElement('script');
  scriptTag.id = 'initial-state';
  head.appendChild(scriptTag);

  scriptTag.appendChild(document.createTextNode(`window.__INITIAL_STATE = ${JSON.stringify(getStore().getState())};`));
  scriptTag.appendChild(
    document.createTextNode(`window.__NAV_SVC_DATA = ${JSON.stringify(getFromLocalStore('__NAV_SVC_DATA'))};`)
  );
};
export const injectState = debounce(rawInjectState, 50, { maxWait: 10 });

//  Pre-render: Extract styles
// !Pre-render: Remove inital styles
export const extractStyles = () => {
  const style = document.getElementById('initial-styles');
  if (style) {
    document.head.removeChild(style);
  }
  let i;
  let j;
  let __INITIAL_STYLES = '';
  for (i = 0; i < document.styleSheets.length; i++) {
    try {
      for (j = 0; j < document.styleSheets[i].rules.length; j++) {
        if (!document.styleSheets[i].href) {
          __INITIAL_STYLES = `${__INITIAL_STYLES + document.styleSheets[i].rules[j].cssText}\n`;
        }
      }
    } catch (err) {
      // do nothing
    }
  }
  injectStyles(__INITIAL_STYLES);
};

export const deleteInitialStyles = () => {
  const e = document.getElementById('initial-styles');
  e.remove();
};

export const handleInitialStyling = () => {
  // Removing style cleanup in as the later classes to not match the initial ones
};
export const handleInitialState = () => {
  injectState();
};
const initialHtml = {};

export const handleSSRFallbackForKey = (key) => {
  try {
    const el = document.querySelector(`[data-ssr-key*=${cleanKey(key)}]`);
    if (!el) return;
    initialHtml[key] = el.outerHTML;
  } catch (e) {
    console.log('handleSSRFallbackForKey error', e);
  }
};
/**
 *
 * @returns Save the pre-rendered html before hydration
 */
export const handleSSRFallbacks = () => {
  if (typeof window === 'undefined') return;
  if (typeof window.__SSR_ELEMENTS === 'undefined') return;

  window.__SSR_ELEMENTS.forEach((key) => {
    handleSSRFallbackForKey(key);
  });
};
export const getHtmlForKey = (key) => {
  const preRenderedContent = initialHtml[key];
  if (preRenderedContent) return preRenderedContent;
  return '';
};
