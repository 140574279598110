import PropTypes from 'prop-types'; // ES6
import React from 'react';
import { getThemeFactory } from '@ic-theme';
import * as cls from './Spinner.styles';

/*
@spinnerColor: #e8008c;

.ICSpinner div {
  top: 50%;
  //font-size: 5px;
  margin: 10px auto;

  border: solid @spinnerColor;

  left: 50%;
  opacity: 0;
  //position: absolute;
  top: 50%;

  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
}

.ICSpinner {
  background: #ffffff;
}

@keyframes pulsate {
  0% {
    transform: scale(.1);
    opacity: 0.0;
  }
  75% {
    opacity: 1;
  }
  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}

*/

const th = getThemeFactory('Spinner', {});

/**
 * Spinner React Component to show spinner on the page until the content gets loaded
 */
export default class Spinner extends React.Component {
  /**
   * Render function for react, called every time state is changed.
   * @returns {XML}
   */
  render() {
    let visible = this.props.visible;
    let diameter = this.props.diameter;

    // Set default values
    if (!diameter) {
      diameter = 50;
    }

    if (typeof visible === 'undefined') {
      visible = false;
    }

    // Setup styles
    const styles = {
      display: 'none',
    };

    if (visible) {
      styles.display = 'block';
    }

    const innerStyles = {
      width: diameter,
      height: diameter,
    };
    const circleWidth = diameter / 5;
    innerStyles.borderRadius = diameter + circleWidth;
    innerStyles.borderWidth = circleWidth;
    const theme = th();
    innerStyles.borderColor = this.props.color || theme.col.secondary.fg;
    innerStyles.borderStyle = 'solid';
    if (this.props.background) {
      styles.background = this.props.background;
    }
    // Return the html for the block of filter buttons
    return (
      <div className={cls.spinner} style={styles} id="counter_react">
        <div className="signal" style={innerStyles} />
      </div>
    );
  }
}

Spinner.propTypes = {
  visible: PropTypes.bool,
  diameter: PropTypes.number,
  color: PropTypes.string,
};

Spinner.defaultProps = {
  background: '#ffffff00',
};
