const punycode = require('./punycode.es6.js');
const entities = require('./entities.json');

const decode = (str) => {
  if (typeof str !== 'string') {
    throw new TypeError('Expected a String');
  }

  return str.replace(/&(#?[^;\W]+;?)/g, (_, match) => {
    let m = /^#(\d+);?$/.exec(match);
    if (m) { // eslint-disable-line
      return punycode.ucs2.encode([parseInt(m[1], 10)]);
    }
    m = /^#[Xx]([A-Fa-f0-9]+);?/.exec(match);
    if (m) {
      return punycode.ucs2.encode([parseInt(m[1], 16)]);
    }
    // named entity
    const hasSemi = /;$/.test(match);
    const withoutSemi = hasSemi ? match.replace(/;$/, '') : match;
    const target = entities[withoutSemi] || (hasSemi && entities[match]);

    if (typeof target === 'number') {
      return punycode.ucs2.encode([target]);
    }
    if (typeof target === 'string') {
      return target;
    }
    return '&' + match;
  });
};

export default decode;
