import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import UILink from 'ic/ui-elem/Link';

/**
 * Class for USPItems inthe header of the page. The main purpose of this component is to render the
 * items in the header of the page and to handle if there is a link associated with the item or not.
 */
class USPItem extends React.Component {
  static get propTypes() {
    return {
      content: PropTypes.string,
      url: PropTypes.string,
    };
  }

  render() {
    const p = this.props;
    return p.url ? (
      <li
        className={'e2e-ver-usp-item ic-cms-usp text-center smartEditComponent'}
        data-smartedit-component-id={this.props.SSRKey}
        data-smartedit-component-uuid={this.props.elementUid}
        data-smartedit-catalog-version-uuid={this.props.contentSlotCatalogVersion}
        data-smartedit-component-type={this.props.componentParentType}
      >
        <div>
          <UILink to={p.url}>{this.props.content}</UILink>
        </div>
      </li>
    ) : (
      <li
        data-smartedit-component-id={this.props.SSRKey}
        data-smartedit-component-uuid={this.props.elementUid}
        data-smartedit-catalog-version-uuid={this.props.contentSlotCatalogVersion}
        data-smartedit-component-type={this.props.componentParentType}
        className={'e2e-ver-usp-item ic-cms-usp text-center smartEditComponent'}
      >
        {this.props.content}
      </li>
    );
  }
}
export default withRouter(USPItem);
