const COOKIEBOT_SCRIPT_ID: string = 'Cookiebot'
const DIRECTIVE_COOKIE_MANAGEMENT_PLATFORM: string = 'DIRECTIVE_CMP'

enum CookiebotCallback {
    ON_ACCEPT = 'CookiebotCallback_OnAccept',
    ON_DECLINE = 'CookiebotCallback_OnDecline',
    ON_LOAD = 'CookiebotCallback_OnLoad',
}

interface CookiebotConsent {
    marketing: boolean
    method: 'implied' | 'explicit'
    necessary: boolean
    preferences: boolean
    stamp: string
    statistics: boolean
}

export class CookiebotUtil {
    constructor(onLoadedCallback: Function = undefined) {
        this.onLoadedCallback = onLoadedCallback
    }

    private firstCall: boolean = true
    private onLoadedCallback: Function

    private updateGTMSettings(): void {
        const consent: CookiebotConsent = window?.[COOKIEBOT_SCRIPT_ID]?.consent
        if (!consent) return

        const dataLayer: any = window?.['dataLayer']
        if (!dataLayer) return

        if (consent?.preferences === true) {
            dataLayer.push({ event: 'cookie_consent_preferences' })
        } else {
            dataLayer.push({ event: 'cookie_denied_preferences' })
        }
        if (consent?.statistics === true) {
            dataLayer.push({ event: 'cookie_consent_statistics' })
        } else {
            dataLayer.push({ event: 'cookie_denied_statistics' })
        }
        if (consent?.marketing === true) {
            dataLayer.push({ event: 'cookie_consent_marketing' })
        } else {
            dataLayer.push({ event: 'cookie_denied_marketing' })
        }
    }

    private getConsentFromCookiebotInstance(): CookiebotConsent {
        const consent: CookiebotConsent = window?.[COOKIEBOT_SCRIPT_ID]?.consent
        if (consent && !this.firstCall) return consent

        if (this.firstCall) {
            this.firstCall = false
            this.updateGTMSettings()
            this.setCookiebotCallbacks()
            return consent
        }
    }

    private setCookiebotCallbacks() {
        window[CookiebotCallback.ON_ACCEPT] = () => this.updateGTMSettings()
        window[CookiebotCallback.ON_DECLINE] = () => this.updateGTMSettings()
        window[CookiebotCallback.ON_LOAD] = () => {
            if (this.onLoadedCallback) {
                this.onLoadedCallback()
            }
        }
    }

    get hasStatisticsConsent(): boolean {
        if (__DEV__) return true
        if (!window) return false
        if (window?.[DIRECTIVE_COOKIE_MANAGEMENT_PLATFORM] === COOKIEBOT_SCRIPT_ID) {
            const consent: CookiebotConsent = this.getConsentFromCookiebotInstance()
            return consent?.statistics === true
        }
        return false
    }

    get cookiebotIsLoaded(): boolean {
        return window?.[COOKIEBOT_SCRIPT_ID]?.consent !== undefined
    }
}
