import { getProductStockStatus, parseImageSubset } from '../products/utils'
import { CartEntry, RecommendedCartEntry } from './interfaces'

export const parseCartEntries = (entries: CartEntry[], fallbackImage: string): CartEntry[] => {
    entries.forEach((entry: CartEntry) => {
        entry.product.imagesSubset = parseImageSubset(entry.product.imagesSubset, fallbackImage)
        entry.stockStatus = getProductStockStatus(entry?.entryStockStatus || entry?.product?.stockStatus)
    })
    return entries
}

export const parseRecommendedCartEntries = (entries: RecommendedCartEntry[], fallbackImage: string): RecommendedCartEntry[] => {
    entries.forEach((entry: RecommendedCartEntry) => {
        entry.product.imagesSubset = parseImageSubset(entry.product.imagesSubset, fallbackImage)
        entry.product.stockStatus = getProductStockStatus(entry?.product?.stockStatus)
    })
    return entries
}
