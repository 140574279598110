import React, { useEffect } from 'react'
import { useErrorBoundary } from 'react-error-boundary'
import { ThemeProvider } from 'styled-components'
import { getThemeFactory } from '@ic-theme'
import { useHistory } from 'react-router'
import { NavLink } from 'react-router-dom'
import Button from '@ui-elem/Button/Button'
import { str } from '@spa-ec-js/services/localeSvc'

const theme = getThemeFactory('GenericError', {})
const th = theme()

const GenericError: React.FC = () => {
    const history = useHistory()
    const { resetBoundary } = useErrorBoundary()

    useEffect(() => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth',
        })
    })

    const goBack = (): void => {
        resetBoundary()
        history.goBack()
    }

    return (
        <>
            <ThemeProvider theme={th}>
                <div className="content-bkg mb-4 py-6 flex">
                    <div className="hidden md:block md:w-1/4" />
                    <div className="w-full md:w1/2 md:px-0">
                        <div className="text-center font-bold text-x2 pb-4">{str('warning.popup.popuptitle')}</div>
                        <div className="flex justify-center">
                            <div className="px-10">
                                <NavLink to={'/'}>
                                    <Button buttonPadding="px-4 py-2" buttonText={str('phrase.take_me_to_startpage')} />
                                </NavLink>
                            </div>
                            <div className={'px-10'}>
                                <Button onClick={goBack} buttonPadding="px-4 py-2" buttonText={str('phrase.take_me_back')} />
                            </div>
                        </div>
                    </div>
                    <div className="hidden md:block md:w-1/4" />
                </div>
            </ThemeProvider>
        </>
    )
}

export default GenericError
