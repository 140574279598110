import React, { useState } from 'react';
import logger from 'score/logSvc';
import { isPuppeteer, getHtmlForKey } from 'core/util/extractStyles';
import cleanKey from 'core/util/cleanKey';
import Sensor from 'react-visibility-sensor-v2';

const log = logger.getLogger('Loader');

const loadDb = {};
if (typeof window !== 'undefined') {
  window.loadDb = loadDb;
}

let hydrated = false;

const isPreRendering = isPuppeteer;
const isPrerendered = !!window.__SSR_ELEMENTS;
export const setStatus2Hydrated = () => {
  hydrated = true;
};

export const stripDiv = (html) => {
  // Remopve the first poart of the html up to the first class attribute
  const firstClassPos = html.indexOf('class="');
  let __html = html.substring(firstClassPos + 7);

  // Copy the content of the string up to the next " character which will extract the classes
  const enfOfClasses = __html.indexOf('"');
  const classesInWrapper = __html.substring(0, enfOfClasses);

  // Remove the classes from the string
  __html = __html.substring(enfOfClasses + 1);

  // Remove everything up to next > character
  const endOfDiv = __html.indexOf('>');
  __html = __html.substring(endOfDiv + 1);

  // Find the last </div> and remove it
  const endOfDiv2 = __html.lastIndexOf('</div>');
  __html = __html.substring(0, endOfDiv2);

  return { __html, classesInWrapper };
};

let scriptTag = typeof document === 'undefined' ? undefined : document.getElementById('initial-state');
const addSSRElemenentsArray = () => {
  const head = document.head || document.getElementsByTagName('head')[0];
  scriptTag = document.createElement('script');
  scriptTag.id = 'ssr-elements';
  head.appendChild(scriptTag);

  scriptTag.appendChild(document.createTextNode('window.__SSR_ELEMENTS = [];'));
};

const addedElements = {};

const addSSRElemenent = (key) => {
  if (addedElements[key]) return;
  const head = document.head || document.getElementsByTagName('head')[0];
  scriptTag = document.createElement('script');
  scriptTag.id = 'ssr-elements';
  head.appendChild(scriptTag);

  scriptTag.appendChild(document.createTextNode(`window.__SSR_ELEMENTS.push('${key}');`));
  addedElements[key] = true;
};
if (isPreRendering) addSSRElemenentsArray();

const moduleOptions = {};

const loadWithSSRFallback = (fun, moduleKey, options) => {
  moduleOptions[moduleKey] = options;
  /**
   * Supported options are:
   * - hydrate: never/hover/delay/always/inView
   */
  return (p) => {
    log.trace('rendering', moduleKey, p.children, p.SSRKey);
    const [mounted, setMounted] = useState(0);
    const _ssrKey = cleanKey(p.SSRKey);

    // Todo avoid recomiling every render
    const load = () => {
      // log.info('loading', moduleKey, p.children, p.SSRKey);
      // The component is not loaded, fetch it
      fun().then((Comp) => {
        loadDb[moduleKey] = Comp.default;
        setMounted(1);
      });
      if (isPreRendering) {
        // When prerendering dont bother with trying to render any
        // fallback content
        return null;
      }
    };
    // Hydration method is based on module options with a possible override on a component level
    let hydrationMethod = p?.hydrate || moduleOptions[moduleKey]?.hydrate || 'always';
    let usingForce = p?.force || moduleOptions[moduleKey]?.force || false;
    if (window.innerWidth < 768 && hydrationMethod !== 'always') {
      hydrationMethod = 'inView';
    }
    if (!window.__SSR_ELEMENTS) {
      hydrationMethod = 'always';
    }

    // This clause defines what to do when the component is not yet loaded
    if (typeof loadDb[moduleKey] === 'undefined') {
      load();
      if (!isPrerendered && isPreRendering) {
        // When prerendering dont bother with trying to render any fallback content
        return null;
      }

      // For mobile views rely on the scrolling for hydrating the non loaded components
      // unless you add the force option
      if (window.innerWidth < 768 && window.__SSR_ELEMENTS && usingForce !== true && hydrationMethod === 'always') {
        const _fallback = getHtmlForKey(_ssrKey);
        let fallback = _fallback;
        if (_fallback.length.length === 0) {
          fallback = '<div></div>';
          load();
        }
        log.trace('Fallback content is:', fallback.length);
        return (
          <Sensor
            partialVisibility={true}
            onChange={(isVisible) => {
              log.trace('Component has visibility status: ', _ssrKey, isVisible);
              if (isVisible) load();
            }}
          >
            <div dangerouslySetInnerHTML={{ __html: fallback }} />
          </Sensor>
        );
      }
      // log.trace('rendering before Hydration Method', hydrationMethod, p.children, p.SSRKey);
      switch (hydrationMethod) {
        case 'delay':
          setTimeout(load, moduleOptions[moduleKey]?.timeout || 1000);
          // In client without the real compoent
          // look for pre-rendered content to use instead
          return <div dangerouslySetInnerHTML={{ __html: getHtmlForKey(_ssrKey) }} />;
        case 'inView':
          return (
            <Sensor
              partialVisibility={true}
              onChange={(isVisible) => {
                if (isVisible) load();
              }}
            >
              <div dangerouslySetInnerHTML={{ __html: getHtmlForKey(_ssrKey) }} />
            </Sensor>
          );
        case 'always':
          load();
          // if (moduleKey !== 'Banner') {
          if (moduleKey === 'Banner' || moduleKey === 'BannerWrapper') {
            // log.trace('rendering in always', moduleKey, p.children, _ssrKey);
            const allHtml = getHtmlForKey(_ssrKey);
            const { __html, classesInWrapper } = stripDiv(allHtml);
            return <div datastrkey={_ssrKey} className={classesInWrapper} dangerouslySetInnerHTML={{ __html }} />;
          }
          return <div dangerouslySetInnerHTML={{ __html: getHtmlForKey(_ssrKey) }} />;
        // }
        // break;
        case 'never':
          return <div dangerouslySetInnerHTML={{ __html: getHtmlForKey(_ssrKey) }} />;
        case 'hover':
        default:
          return (
            <div
              dangerouslySetInnerHTML={{ __html: getHtmlForKey(_ssrKey) }}
              onMouseEnter={load}
              onTouchStart={load}
              onScroll={load}
              onClick={load}
            />
          );
      }
    }

    const el = React.createElement(loadDb[moduleKey], p, p.children);
    if (isPreRendering) {
      addSSRElemenent(_ssrKey);
    }

    return <>{el}</>;
  };
};
export default loadWithSSRFallback;
