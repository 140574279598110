import React from 'react'
import { useHistory } from 'react-router'
import { NavLink } from 'react-router-dom'
import { useErrorBoundary } from 'react-error-boundary'
import ContentSlot from '@spa-ec-js/components/ContentSlot/ContentSlotMod'
import Icon, { Icons } from '@ui-elem/Icon/Icon'
import Button from '@ui-elem/Button/Button'
import { str } from '@spa-ec-js/services/localeSvc'
import { Metadata } from '@spa-ec-js/displayComponents/Head'

import { svcData as svcNavData } from '@spa-ec-js/services/navigationSvc/navigationSvcMod'
import GenericError from './GenericError'

export enum ErrorTypes {
    GENERIC = 'generic',
    NOT_FOUND = 'notFound',
    INACTIVE_CAMPAIGN_PAGE = 'InactiveCampaignPage',
}

interface SpaErrorInterface {
    errorType?: ErrorTypes
    pageId?: string
    name?: string
    message?: string
}

export class SpaError extends Error implements SpaErrorInterface {
    name: string = SpaError.name
    errorType: ErrorTypes
    message: string
    pageId: string
    constructor(error: SpaErrorInterface = {}) {
        super()
        this.errorType = error.errorType
        this.message = error.message
        this.pageId = error.pageId
    }
}

type Props = {
    error: SpaErrorInterface
}

const ErrorComponent: React.FC<Props> = ({ error }) => {
    const { resetBoundary } = useErrorBoundary()
    const history = useHistory()
    /**
     * Reset the ErrorBoundry when route changes
     */
    history.listen(resetBoundary)

    let errorType: ErrorTypes = error.errorType || ErrorTypes.GENERIC
    if (error.name !== SpaError.name) {
        /**
         * Unhandled native Error was thrown instead of SpaError
         * Display generic error message
         */
        errorType = ErrorTypes.GENERIC
    }

    if (errorType === ErrorTypes.GENERIC) {
        return <GenericError />
    }

    let pageId
    switch (errorType) {
        case ErrorTypes.INACTIVE_CAMPAIGN_PAGE:
            pageId = 'inactivecampaign'
            break
        case ErrorTypes.NOT_FOUND:
            pageId = 'notFound'
            break
        default:
            pageId = error.pageId
    }

    const metadata: any[] = svcNavData.routes['/notFound'] || []
    const inactiveCampaignNavdata: any[] = svcNavData.routes['/inactivecampaign'] || []

    const goBack = (): void => {
        resetBoundary()
        history.goBack()
    }

    return (
        <>
            <Metadata metadata={pageId === 'inactivecampaign' ? inactiveCampaignNavdata : metadata} appendSiteName={true} />
            <div className="content-bkg py-6 flex">
                <div className="hidden md:block md:w-1/4" />
                <div className="w-full md:w-1/2pl-4 pr-2 md:px-0">
                    <div className="flex justify-center">
                        <Icon icon={Icons.RegCompass} size={48} />
                    </div>
                    {pageId ? (
                        <div>
                            <ContentSlot
                                key={pageId + '---MiddleContent'}
                                SSRKey={pageId + '---MiddleContent'}
                                iid={pageId + '---MiddleContent'}
                                position={'MiddleContent'}
                                pageId={pageId}
                            />
                        </div>
                    ) : null}
                    <div className="flex justify-center">
                        <div className="px-10">
                            <NavLink to={'/'}>
                                <Button
                                    onClick={resetBoundary}
                                    fluid={true}
                                    buttonPadding="p-4"
                                    buttonText={str('phrase.take_me_to_startpage')}
                                />
                            </NavLink>
                        </div>
                        <div className={'px-10'}>
                            <Button onClick={goBack} fluid={true} buttonPadding="p-4" buttonText={str('phrase.take_me_back')} />
                        </div>
                    </div>
                </div>
                <div className="hidden md:block md:w-1/4" />
            </div>
        </>
    )
}

export default ErrorComponent
