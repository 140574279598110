/**
 * Created by knuts on 2016-04-13.
 */
import { combineReducers } from 'redux';
import localeSvcReducer from 'seco/localeSvc/localeSvcReducer';
import siteLocaleSvcReducer from 'seco/siteLocaleSvc/siteLocaleSvcReducer';
/**
 * Delegation of responsibility to other reduvers for subsets of the state tree.
 */
export default combineReducers({
  localeSvc: localeSvcReducer,
  siteLocaleSvc: siteLocaleSvcReducer,
});
