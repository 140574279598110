// Read a page's GET URL variables and return them as an associative array.
import logger from 'score/logSvc';
import cookie from 'core/util/ic-cookie';
import forEach from 'lodash/forEach';

const log = logger.getLogger('BrowserSvc');

/**
 * Validation function for determining execution environment. Mainly used to separate if the code is running on the
 * server-side or in the browser.
 * @returns {boolean} True if the execution environment is the browser.
 */
export const inBrowser = () => typeof Java === 'undefined';

/**
 * Function to set a new value of url parameter
 * @param p : parameter
 * @param val : parameter new value
 */
export const setUrlParam = (p, val) => {
  if (typeof history === 'undefined') {
    return;
  }

  if (typeof history.pushState === 'undefined') {
    return;
  }
  const vars = logger.getUrlVars();

  vars[p] = val;

  let path = `${location.pathname}?`;

  forEach(vars, (v, k) => {
    path = `${path}${k}=${v}&`;
  });

  // Remove trailing &
  path = path.slice(0, -1);
  const stateObj = {};
  history.pushState(stateObj, document.title, path);
};

/**
 * Function to remove a parameter from URL if present
 * @param p : parameter to be removed
 */
export const removeUrlParam = (p) => {
  if (typeof history === 'undefined') {
    return;
  }

  if (typeof history.pushState === 'undefined') {
    return;
  }
  const vars = logger.getUrlVars();
  let path = `${location.pathname}?`;

  forEach(vars, (v, k) => {
    if (k !== p) {
      path = `${path}${k}=${v}&`;
    }
  });

  // Remove trailing &
  path = path.slice(0, -1);
  const stateObj = {};
  history.pushState(stateObj, document.title, path);
};

/**
 * Function to get the value of URL parameter
 * @param p : parameter whose value to be retrieved
 * @returns value of parameter p
 */
export const getUrlParam = (p) => {
  const vars = logger.getUrlVars();

  return vars[p];
};

/**
 * Function to get last URL parameter
 * @returns value
 */
export const getLastUrlParam = () => {
  if (typeof window === 'undefined') {
    return {};
  }
  if (typeof window.location === 'undefined') {
    return {};
  }
  if (window.location.href.indexOf('/') < 0) {
    return {};
  }
  const urlParam = window.location.href.slice(window.location.href.lastIndexOf('/') + 1).split('?');
  return urlParam[0];
};

/**
 * Function to set an item in session storage
 * @param k: item to be set in session
 * @param v: value of item k
 */
export const sessionSet = (k, v) => {
  if (typeof sessionStorage === 'undefined') {
    return;
  }
  sessionStorage.setItem(k, v);
};

/**
 * Function to get the value of an item from session storage
 * @param k : item whose value to be retrieved from session storage
 * @returns value of item k
 */
export const sessionGet = (k) => {
  if (typeof sessionStorage === 'undefined') {
    return;
  }
  return sessionStorage.getItem(k);
};
/**
 * Function to get the value of an item from session storage
 * @param k : item whose value to be retrieved from session storage
 * @returns value of item k
 */
export const sessionRemove = (k) => {
  if (typeof sessionStorage === 'undefined') {
    return;
  }
  return sessionStorage.removeItem(k);
};

/**
 * Function to set an item in local storage
 * @param k: item to be set in local
 * @param v: value of item k
 */
export const localStorageSet = (k, v) => {
  if (typeof localStorage === 'undefined') {
    return;
  }
  localStorage.setItem(k, v);
};

/**
 * Function to get the value of an item from local storage
 * @param k : item whose value to be retrieved from local storage
 * @returns value of item k
 */
export const localStorageGet = (k) => {
  if (typeof localStorage === 'undefined') {
    return;
  }
  return localStorage.getItem(k);
};
/**
 * Function to get the value of an item from local storage
 * @param k : item whose value to be retrieved from local storage
 * @returns value of item k
 */
export const localStorageRemove = (k) => {
  if (typeof localStorage === 'undefined') {
    return;
  }
  return localStorage.removeItem(k);
};

/**
 * Function to set an item in cookie storage
 * @param k: item to be set in cookie
 * @param v: value of an item
 */
export const cookieSet = (k, v, o) => {
  if (typeof sessionStorage === 'undefined') {
    return;
  }

  let options = o;
  if (!options) {
    options = {};
  }
  if (!options.path) {
    options.path = '/';
  }

  cookie.set(k, v, options);
};

/**
 * Function to get the value of an item from cookie storage
 * @param k: item whose value to be retrieved from cookie
 * @returns value of item k
 */
export const cookieGet = (k) => {
  if (typeof sessionStorage === 'undefined') {
    return;
  }
  return cookie.get(k);
};

/**
 * Redirects the browser to the provided url. Simple wrapper but main gain by using it is testability as the
 * function can be spyed upon in jasmine and mocked.
 * @param url
 */
export const redirect = (url) => {
  if (typeof location !== 'undefined') {
    location.href = url;
  }
};

export default {
  inBrowser,
  setUrlParam,
  removeUrlParam,
  getUrlParam,
  getLastUrlParam,
  sessionSet,
  sessionGet,
  sessionRemove,
  localStorageSet,
  localStorageGet,
  localStorageRemove,
  cookieSet,
  cookieGet,
  redirect,
};
