/**
 * ---
 * title: Module utils
 * ---
 */
import { combineReducers, createStore } from 'redux';
import moduleToReducer from 'core/redux/moduleToReducer';
import forEach from 'lodash/forEach';
import isEqual from 'lodash/isEqual';
import merge from 'lodash/merge';
import cloneDeep from 'fast-clone';
import logger from 'score/logSvc';
import { connect } from 'react-redux';

const log = logger.getLogger('Module Utils');

/**
 * ## setupInitialState
 * Function that handles the initial state for a module. It will set the initial state for the
 * module to the modules initial state in the basic case without multipleInstances. When there
 * are multiple instances room is prepared for the instances state in an object.
 * @param {*} a (deprecated)
 * @param {*} conf
 */
// Todo: Remove a and fix accordingly in  modules
export const setupInitialState = (_a, conf) => {
  if (conf.multipleInstances) {
    return {};
  }
  return conf.initialState;
};

/**
 * ## areStatePropsEqual
 * Function that checks if the two state objects are equal or not. This is used when connecting the
 * state object to the rendering class and specifically to decide if the data has changed when trying
 * to decide to render or not. The function is not called directly but is provided to connect as an
 * argument so that connect can call it when rendering. This function is use but is beeing replaced
 * by super connect. Note that functions are treated as equal regardless of if their code is the same
 * or not, this is functions are always treated as different by === even if the ocde in them are the
 * same. In the case of modules this is not an issue as functions here is getters that does not change.
 *
 * @param {*} a the old state object
 * @param {*} b the new state object
 */
export const areStatePropsEqual = (a, b) => {
  // Check that keys are equal
  if (a.logMe) {
    log.info('INST', a.logMe, a.entries.length, b.entries.length);
  }

  const keys = Object.keys(a);
  const keys2 = Object.keys(b);
  if (!isEqual(keys, keys2)) {
    return false;
  }
  let res = true;
  for (let i = 0; i < keys.length; i++) {
    const k = keys[i];
    if (typeof a[k] !== 'function' && !isEqual(a[k], b[k])) {
      res = false;
      break;
    }
  }

  return res;
};

/**
 * ## setupMutations
 * Function that is called during module setup. It takes the modules mutations and if there are no multiple
 * instances the original mutations is returned. If the config is set to multiple instances a new list of mutations
 * is created from mutations. This new mutations list will use the original mutations to mutate the objets state
 * partitioned by iid. This is how the multiple instance modules gets it state partitioned by iid.
 * @param {*} mutations
 * @param {*} conf
 */
export const setupMutations = (mutations, conf) => {
  const mutationKeys = Object.keys(mutations);

  if (!conf.multipleInstances) {
    return mutations;
  }
  // We are using multiple instances
  const newMutations = {};
  mutationKeys.forEach((k) => {
    newMutations[k] = (state, action) => {
      if (!state[action.iid]) {
        state[action.iid] = cloneDeep(conf.initialState);
        // supposed to be like this, using proxy
      }
      if (mutations[k].useRootState === true || action.applyToRoot) {
        mutations[k](state, action);
      } else {
        mutations[k](state[action.iid], action);
      }
    };
  });

  return newMutations;
};

/**
 * ## mapItems
 * This is a function that handles mapping the of the data for the module depending of whether or not we are
 * using multiple instances.
 * @param state
 * @param conf
 * @param instanceId
 */
// Todo: move cloneDeep from all modules into mapItems.
export const mapItems = (state, conf, instanceId) => {
  // setup mapping between module props and the state object

  // Handle when objects does not exist
  if (!state.modules) {
    return conf.initialState;
  }
  // This make module startup more robust as in some cases
  // this can be called bore the state is properly setup
  // Todo: Investigate if this is still required, new redux in place since this was put into place
  if (!state.modules[conf.name]) {
    return conf.initialState;
  }

  if (conf.multipleInstances) {
    if (!state.modules[conf.name][instanceId]) {
      return conf.initialState;
    }

    // We are using instances and the object exists in the state
    return state.modules[conf.name][instanceId];
  }

  // Object exists and we are not using instances
  return state.modules[conf.name];
};

/**
 * ## mapService
 * Function that maps the data in the state of the service to
 * the properties in the module that is made available to the
 * React part of the module for rendering.
 * @param {*} service
 * @param {*} instance
 * @param {*} subgroup
 */
export const mapService = (service, instance, subgroup) => {
  const keys = Object.keys(service);

  let dest = instance;
  if (subgroup) {
    if (!instance[subgroup]) {
      instance[subgroup] = {};
    }

    dest = instance[subgroup];
  }

  // Todo remove the if statement below but keep the try/catch
  forEach(keys, (k) => {
    if (typeof service[k] === 'function') {
      dest[k] = service[k];
    } else {
      try {
        if (typeof dest[k] === 'object') {
          dest[k] = merge(dest[k], service[k]);
        } else {
          dest[k] = service[k];
        }
      } catch (e) {
        log.error('Error:', e);
        log.error('Key: ', k);
        log.error('Dest: ', dest[k]);
        log.error('Incoming value:', service[k]);
      }
    }
  });
};

export const mapGetters = (service, instance, subgroup, names) => {
  if (!instance[subgroup]) {
    instance[subgroup] = {};
  }
  names.forEach((name) => {
    instance[subgroup][name] = service[name];
  });
};
export const mapActions = (service, subgroup, names) => {
  const res = {};
  const grp = {};
  names.forEach((name) => {
    grp[name] = service[name];
  });

  res[subgroup] = grp;
  return res;
};

/**
 * Maps the value of a set of string keys into the state which certifies re-redering if the strings change.
 * @param {*} state
 * @param {*} instance
 * @param {*} subgroup
 * @param {*} keys
 */
export const mapStrings = (state, instance, subgroup, _keys) => {
  if (!instance[subgroup]) {
    instance[subgroup] = {};
  }
  if (!(state.serviceData.localeSvc && state.serviceData.localeSvc.strings)) return;

  _keys.forEach((k) => {
    instance[subgroup][k] = state.serviceData.localeSvc.strings[k];
  });
};

/**
 * ## createStoreForModule
 * This function is used for testing of modules. Not activly used, this is a candidate for removal.
 * @param {*} _module
 */
export const createStoreForModule = (_module) => {
  const mod = {};
  mod[_module.name] = moduleToReducer(_module);
  return createStore(
    combineReducers({
      modules: combineReducers(mod),
    })
  );
};

/**
 * ## getModuleState
 * This function is used by a modules to extract the state of a specific, possibly other, module. The function extract the
 * state of the single module from the provided full state and takes into account if the  module to get the state for has
 * multiple instances or not.
 * @param {*} state The global state
 * @param {*} iid The unique module id (only used when the module is using multiple instances)
 * @param {*} conf The calling modules conf struct containing module {name, multipleInstances, initialState}
 */
export const getModuleState = (state, iid, { name, multipleInstances, initialState }) => {
  if (multipleInstances) {
    if (state.modules[name] && state.modules[name][iid]) {
      return state.modules[name][iid];
    }
    return initialState;
  }

  if (state.modules[name]) {
    return state.modules[name];
  }

  return initialState;
};

/**
 * ## compare
 * Compares two states, the state used at the last rendering and the state to be used by this rendering.
 * If a diff is found the function returns true if there are changes the function returns true.
 * Functions are excluded from the comparison in such way that they are counted as never changing.
 * The function used by superConnect
 * @param {*} before state used at the last rendering
 * @param {*} after state to be used by this rendering
 */

/* eslint-disable */
export const isEq = (a, b) => {
  if (typeof a === 'undefined') return true;
  if (typeof a === 'function' && typeof b === 'function') return true;
  if (a === b) return true;

  if (a && b && typeof a === 'object' && typeof b === 'object') {
    if (a.constructor !== b.constructor) return false;

    let length;
    let i;
    if (Array.isArray(a)) {
      length = a.length;
      if (length != b.length) return false;
      for (i = length; i-- !== 0; ) if (!compareCust(a[i], b[i])) return false;
      return true;
    }

    if (a.constructor === RegExp) return a.source === b.source && a.flags === b.flags;
    if (a.valueOf !== Object.prototype.valueOf) return a.valueOf() === b.valueOf();
    if (a.toString !== Object.prototype.toString) return a.toString() === b.toString();

    const keys = Object.keys(a);
    length = keys.length;
    if (length !== Object.keys(b).length) return false;

    for (i = length; i-- !== 0; ) if (!Object.prototype.hasOwnProperty.call(b, keys[i])) return false;

    for (i = length; i-- !== 0; ) {
      const key = keys[i];

      if (key === '_owner' && a.$$typeof) {
        // React-specific: avoid traversing React elements' _owner.
        //  _owner contains circular references
        // and is not needed when comparing the actual elements (and not their owners)
        continue;
      }

      if (!compareCust(a[key], b[key])) return false;
    }

    return true;
  }

  // true if both NaN, false otherwise
  return a !== a && b !== b;  
};
function equal(a, b) {
  if (a === b) return true;
  if (typeof a === 'function' && typeof b === 'function') return true;

  if (a && b && typeof a == 'object' && typeof b == 'object') {
    if (a.constructor !== b.constructor) return false;

    var length, i, keys;
    if (Array.isArray(a)) {
      length = a.length;
      if (length != b.length) return false;
      for (i = length; i-- !== 0;)
        if (!equal(a[i], b[i])) return false;
      return true;
    }

    if (a.constructor === RegExp) return a.source === b.source && a.flags === b.flags;
    if (a.valueOf !== Object.prototype.valueOf) return a.valueOf() === b.valueOf();
    if (a.toString !== Object.prototype.toString) return a.toString() === b.toString();

    keys = Object.keys(a);
    length = keys.length;
    for (i = length; i-- !== 0; )
      if (typeof a[keys[i]] === 'undefined') {
        try {delete b[keys[i]];} catch(e){}
        keys.splice(i, 1);
        length -= 1;
      }
    if (length !== Object.keys(b).length) return false;

    for (i = length; i-- !== 0;)
      if (!Object.prototype.hasOwnProperty.call(b, keys[i])) return false;

    for (i = length; i-- !== 0;) {
      var key = keys[i];

      if (key === '_owner' && a.$$typeof) {
        // React-specific: avoid traversing React elements' _owner.
        //  _owner contains circular references
        // and is not needed when comparing the actual elements (and not their owners)
        continue;
      }

      if (!equal(a[key], b[key])) return false;
    }

    return true;
  }

  // true if both NaN, false otherwise
  return a!==a && b!==b;
};
/* eslint-enable */
const compareCust = (a, b) => {
  const res = equal(a, b);

  return res;
};
export const deepCompare = compareCust;
/**
 * ## superConnect
 * This function connects a React component to a Redux store so that the modules state and actions is available as properties to the rect component.
 * It provides its connected component with the pieces of the data it needs from the store, and the functions it can use to dispatch actions to the store.
 * It does not modify the component class passed to it; instead, it returns a new, connected component class that wraps the component you passed in.
 * @param {*} module
 * @param {*} component
 */
export const superConnect = (module, component) => {
  const compareStateProps2 = (before, after) => deepCompare(before, after, '1' + module.name);
  const compareStateProps3 = (before, after) => deepCompare(before, after, '1' + module.name);
  const compareStateProps4 = (before, after) => {
    return deepCompare(before, after, '1' + module.name);
  };
  return connect(module.getters, module.actions, undefined, {
    // pure: true,
    // areStatesEqual: compareStateProps1,
    areOwnPropsEqual: compareStateProps2,
    areStatePropsEqual: compareStateProps3,
    // areMergedPropsEqual: compareMergedProps,
    areMergedPropsEqual: compareStateProps4,
  })(component);
};

export const decorate = (__module, _decorators) => {
  let module = { ...__module };
  if (typeof _decorators === 'undefined') return module;
  if (typeof _decorators === 'function') return _decorators(module);
  if (Array.isArray(_decorators)) {
    for (let i = 0; i < _decorators.length; i++) {
      module = _decorators[i](module);
    }
  }
  return module;
};

/**
 * ## cacheInvalid
 * Heleper function for deteermining if a cache item is invalid.
 *
 */
export const cacheInvalid = (lastUpdate, maxAge) => new Date().getTime() - lastUpdate >= maxAge || logger.cacheDisabled();

const funDb = {};
export const singleCall = (name, fun) => {
  if (typeof funDb[name] === 'undefined') {
    funDb[name] = true;
    fun();
  }
};

export const mergeForDecorators = (o, initialState, getters, actions, mutations, conf) => {
  if (conf.multipleInstances) {
    o.state = {};
  } else {
    o.state = merge(o.state, initialState);
  }

  const orgActions = o.actions;
  const act = (dispatch, ownProps) => {
    const org = orgActions(dispatch, ownProps);
    const decoration = actions(dispatch, ownProps);
    return merge(org, decoration);
  };
  o.actions = act;
  o.mutations = merge(o.mutations, setupMutations(mutations, conf));
  return o;
};

export const mergeInitialState = (initialState, _decorators) => {
  let state = {};
  if (Array.isArray(_decorators)) {
    for (let i = 0; i < _decorators.length; i++) {
      state = merge(state, _decorators[i]());
    }
  }
  state = merge(state, initialState);
  return state;
};
