import { CookiebotUtil } from './CookiebotUtil'

enum CookiebotEvents {
    ON_ACCEPT = 'CookiebotOnAccept',
    ON_DECLINE = 'CookiebotOnDecline',
}

const WAIT_FOR_LOADING_INTERVAL: number = 250
const WAIT_FOR_LOADING_RETRIES: number = 40

export class MatomoUtil {
    private waitForTrackerCount: number = 0

    initMatomo(): void {
        document.addEventListener('DOMContentLoaded', this.isLoadedPoller.bind(this))
    }

    private isLoadedPoller(): void {
        if (!window['_paq'] || !this.cookiebotIsLoaded) {
            if (this.waitForTrackerCount < WAIT_FOR_LOADING_RETRIES) {
                this.waitForTrackerCount++
                setTimeout(this.isLoadedPoller.bind(this), WAIT_FOR_LOADING_INTERVAL)
            }
        } else {
            window.addEventListener(CookiebotEvents.ON_ACCEPT, this.setConsent)
            window.addEventListener(CookiebotEvents.ON_DECLINE, this.setConsent)
        }
    }

    private setConsent(): void {
        if (this.hasCookiebotStatisticsConsent) {
            window['_paq'].push(['setCookieConsentGiven'])
            window['_paq'].push(['setConsentGiven'])
        } else {
            window['_paq'].push(['forgetCookieConsentGiven'])
            window['_paq'].push(['forgetConsentGiven'])
        }
    }

    private get hasCookiebotStatisticsConsent(): boolean {
        const cookiebot: CookiebotUtil = new CookiebotUtil()
        return cookiebot.hasStatisticsConsent
    }

    private get cookiebotIsLoaded(): boolean {
        const cookiebot: CookiebotUtil = new CookiebotUtil()
        return cookiebot.cookiebotIsLoaded
    }
}
