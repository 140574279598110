import cloneDeep from 'fast-clone';
import logger from 'score/logSvc';

const log = logger.getLogger('siteLocaleSvc');

const defaultState = {
  siteLocale: 'nordicink_sv',
};

/**
 * Reducer for the <%= name %>.
 * @param {Object} state  - The incoming state object containing the subset of the application state that is the
 *                          responsibility the <%= name %>.
 * @param {Object} action - The action to be applied to the state and thus creating a new state.
 * @return {Object} state - A new state created from the old state and the action.
 */
export default function localeStrings(oldState = defaultState, action) {
  const state = cloneDeep(oldState);
  switch (action.type) {
    case 'SITE_LOCALE_STRINGS':
      state.strings = action.data;
      state.siteLocale = action.siteLocale;
      break;
    case 'SET_SITE_LOCALE':
      state.siteLocale = action.siteLocale;
      break;
    default:
      return state;
  }

  return state;
}
