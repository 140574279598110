import React from 'react';
import { Helmet } from 'react-helmet';
import { NAME as appReducerName } from '@spa-core/store/app/constants';
import { getReducer } from '@spa-core/legacy-adapter/utils';

const Head = (p) => {
  let title = p.title;
  if (!title) {
    title = p.metaTitle;
  }
  let titleStr;
  if (p.subTitle) {
    titleStr = title ? title + ' | ' + p.subTitle : p.subTitle;
  } else {
    titleStr = title;
  }

  // Make deployment easier, when data is changed to Dukade Bordet remove this
  // titleStr = titleStr.replace('DukadeBordet', 'Dukade Bordet');

  let canonicalUrl = p.url ? p.url : location.href.replace(/#.*/, '');
  // if url contains a query string, remove it
  canonicalUrl = canonicalUrl.replace(/\?.*/, '');

  // decode the url
  canonicalUrl = decodeURIComponent(canonicalUrl);

  // if url contains a '+' sign, replace it with a 'plus'
  canonicalUrl = canonicalUrl.replace(/\+/g, 'plus');

  let robots = p.metaRobots ? p.metaRobots : 'index, follow';

  return (
    <Helmet>
      <title>{titleStr}</title>
      <meta name="keywords" content={p.keywords} />
      <meta name="description" content={p.metaDescription} />
      <meta name="title" content={p.metaTitle} />
      <link rel="canonical" href={canonicalUrl} />
      <meta name="robots" content={robots} />
    </Helmet>
  );
};

const valSelect = (prio1, prio2, prio3, prio4) => {
  if (prio1) {
    return prio1;
  }
  if (prio2) {
    return prio2;
  }
  if (prio3) {
    return prio3;
  }
  if (prio4) {
    return prio4;
  }

  return '';
};

export const Metadata = (p) => {
  const metadata = p.metadata ? p.metadata : {};
  const sessionConfig = getReducer(appReducerName).sessionConfig;
  let titleStr = valSelect(p.title, metadata.metaTitle, metadata.title, p.metaTitle);
  const metaDescription = valSelect(p.metaDescription, metadata.metaDescription);
  if ((typeof titleStr === 'string' && titleStr.includes('undefined')) || typeof titleStr === 'undefined') {
    titleStr = '';
  }
  if (p.appendSiteName) {
    /**
     * TODO:
     * Can this be smarter?
     * No site specific code should be in spa-ec
     */
    const siteName = sessionConfig.siteName === 'dammsugarpasar' ? 'dammsugarpasar.nu' : sessionConfig.siteName;
    if (titleStr && !titleStr.endsWith(`| ${siteName}`)) {
      titleStr = `${titleStr} | ${siteName}`;
    }
  }
  let canonicalUrl = p.url ? p.url : location.href.replace(/#.*/, '');
  // if url contains a query string, remove it
  canonicalUrl = canonicalUrl.replace(/\?.*/, '');

  // decode the url
  canonicalUrl = decodeURIComponent(canonicalUrl);

  // if url contains a '+' sign, replace it with a 'plus'
  canonicalUrl = canonicalUrl.replace(/\+/g, 'plus');

  let robots = metadata.metaRobots ? metadata.metaRobots : 'index, follow';

  return (
    <Helmet>
      <title>{titleStr}</title>
      <meta name="keywords" content={metadata.keywords} />
      <meta name="description" content={metaDescription} />
      {/* <meta name="title" content={metaTitleStr} /> */}
      <link rel="canonical" href={canonicalUrl} />
      <meta name="robots" content={robots} />
      {p.seoData ? (
        <script className="structured-data-list" type="application/ld+json">
          {JSON.stringify(p.seoData)}
        </script>
      ) : null}
    </Helmet>
  );
};

export default Head;
