export const NAME: string = 'CartTable'

export type CartTableTheme = {
    headerColor?: string
    separateSubscriptionHeader?: boolean
    showPerPiecePriceTitle?: boolean
    productNameWidthClassname?: string
    productNameWidthExceptCartPage?: string
    stockColumnWidth?: string
    totalColumnWidth?: string
    totalColumnWidthExceptCartPage?: string
    showProductDetails?: boolean
    showCartPriceTitle?: boolean
    showPerKronaInCartTable?: boolean
    showProductUnit?: boolean
}
