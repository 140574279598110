import cloneDeep from 'fast-clone';

const defaultState = {
  locale: 'en',
};

/**
 * Reducer for the <%= name %>.
 * @param {Object} state  - The incoming state object containing the subset of the application state that is the
 *                          responsibility the <%= name %>.
 * @param {Object} action - The action to be applied to the state and thus creating a new state.
 * @return {Object} state - A new state created from the old state and the action.
 */
export default function localeStrings(oldState = defaultState, action) {
  const state = cloneDeep(oldState);
  switch (action.type) {
    case 'LOCALE_STRINGS':
      state.strings = action.data;
      state.locale = action.locale;
      break;
    case 'SET_LOCALE':
      state.locale = action.locale;
      break;
    default:
      return state;
  }

  return state;
}
