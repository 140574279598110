export const NAME: string = 'ContentPage'

export type ContentPageTheme = {
    showSideNavigationOnStaticPages?: boolean
    showTitleOnContentPageDesktop?: boolean
    showTitleOnContentPageMobile?: boolean
    showHomepageCategorySection?: boolean
    sideContentPadding?: string
    sideContentWidth?: string
    linkColor?: string
    linkColorHover?: string
}
