import {
    Categories,
    CategoriesStore,
    Category,
    FetchedSubcategoriesPayload,
    NavigationTrees,
    SelectedNavigationTree,
    SubcategoryNode,
    ModelsListCategory,
    BrandsListCategory,
} from '@spa-core/store/categories/interfaces'
import { findNode4Cat, findNodeByUid } from '@spa-ec-js/services/navigationSvc/tree-tools'
import cloneDeep from 'fast-clone'
import { Action } from '../interfaces'
import { ActionTypes, CATEGORY_CACHE_TTL_SECONDS, FetchCategoryMode } from './constants'

export const INITIAL_STATE: CategoriesStore = {
    categoryCode: '',
    categories: {},
    lastUpdate: new Date('1970-01-01'),
    loading: false,
    appendingProducts: false,
    sorting: 'freeCategoriesSort',
    navigationTrees: {},
    latestNavigationTree: undefined,
    latestSectionTitle: '',
    selectedNavigationTrees: [],
    userLimits: {},
    allFilters: {},
    selectedFacets: {},
    fetchingFilterCode: '',
    subcategories: {},
    brandsListCategories: {},
    modelsListCategories: {},
}

const filterFacets = (category) => {
    return category.facets.filter((facetData) => {
        if (facetData.code !== 'inStock') {
            return true
        }
        // Hide inStock filter if all products in result are in stock
        const hideInStockFilter =
            facetData.values &&
            facetData.values[0].count === (category.results ? category.results.length : 0) &&
            facetData.values[0].selected !== true
        return !hideInStockFilter
    })
}

const categoryPageHeadingHandling = (category) => {
    if (category.alternativeCategoryHeader) {
        category.categoryPageHeading = category.alternativeCategoryHeader
    } else if (
        category.parentCategoryName &&
        category.parentCategoryName.length > 1 &&
        category.categoryName &&
        category.categoryName.length > 1
    ) {
        category.categoryPageHeading = `${category.parentCategoryName} - ${category.categoryName}`
    } else if (
        (!category.parentCategoryName || (category.parentCategoryName && category.parentCategoryName.length === 1)) &&
        category.categoryName &&
        category.categoryName.length > 1
    ) {
        category.categoryPageHeading = category.categoryName
    }
}

const categoryProductsHandling = (category, mode, state, categoryCode) => {
    if (mode === FetchCategoryMode.APPEND && state.categories[categoryCode]) {
        const prevCategory = state.categories[categoryCode] as Category
        const prevList = cloneDeep(prevCategory.productCodes || [])
        category.productCodes = prevList.concat(category.results.map(({ code, slimDTO }) => ({ isSlim: slimDTO === true, code })))
    } else {
        category.productCodes = category.results.map(({ code, slimDTO }) => ({ isSlim: slimDTO === true, code }))
    }
    delete category.results
}

const parseCategoryData =
    (state) =>
    ({ categoryCode, result, onlyUpdateCategory, headerNodes, mode }) => {
        const category = cloneDeep(result)
        const res = findNode4Cat(categoryCode, headerNodes)
        const node = res ? res.node : {}
        const section = findNodeByUid(node.sectionUid, headerNodes)
        const { navigationTrees, categories, selectedNavigationTrees, latestNavigationTree, latestSectionTitle } = state

        if (!onlyUpdateCategory && !navigationTrees[node.sectionUid]) {
            navigationTrees[node.sectionUid] = cloneDeep(section)
            // window.trees = trees
        }

        let updatedNavigation: any
        if (section) {
            category.categoryUid = node.uid
            category.categoryPath = res.pathArr
            category.sectionUid = node.sectionUid
            category.sectionPath = section.pathArr
            category.sectionTitle = section.node.title
            // Expanding category - fortunately we have the path saved so we can
            // get a direct reference to the node
            if (!onlyUpdateCategory) {
                updatedNavigation = setNavigationTree(navigationTrees, selectedNavigationTrees, node.sectionUid, res.pathArr)
            }
        }

        category.isMainCategory = node && node.uid === node.sectionUid
        category.hasChildren = node && node.nodes && node.nodes.length > 0
        category.metadata = {
            title: category.title,
            metaTitle: category.metaTitle,
            metaDescription: category.metaDescription,
            description: category.description,
            keywords: category.keywords,
        }
        categoryPageHeadingHandling(category)

        category.categoryPageDescription = { __html: category.description }
        category.infoText = category.infoText ? { __html: category.infoText } : null

        /**
         * Get product codes, only keep the product code in the categories store
         * The full (or slim) product data is stored in the products store
         */
        categoryProductsHandling(category, mode, state, categoryCode)

        if (category.subCategories) {
            /**
             * Get product codes for subCategories, only keep the product code in the categories store
             * The full (or slim) product data is stored in the products store
             */
            category.subCategories.forEach((cat: any) => {
                cat.productsForDisplay.productCodes = cat.productsForDisplay.results.map(({ code, slimDTO }) => ({
                    isSlim: slimDTO === true,
                    code,
                }))
                delete cat.productsForDisplay.results
            })
        }
        category.lastReference = new Date().getTime()

        // Here we add a custom logic to find and remove in-eligible facet that is returned in the rest call
        let facets
        if (category.facets) {
            facets = filterFacets(category)
        }

        const hydratedCategories = {
            ...categories,
            [categoryCode]: category,
        }

        const setNavigationTrees = updatedNavigation?.navigationTrees || navigationTrees
        return {
            ...state,
            categories: hydratedCategories,
            loading: false,
            appendingProducts: false,
            categoryCode,
            node,
            facets,
            navigationTrees: setNavigationTrees,
            latestNavigationTree: setNavigationTrees[node.sectionUid] || latestNavigationTree,
            latestSectionTitle: category.sectionTitle || latestSectionTitle,
            selectedNavigationTrees: updatedNavigation?.selectedNavigationTrees || selectedNavigationTrees,
            lastUpdate: new Date(),
        }
    }

const parseSetCategory =
    (state) =>
    ({ categoryPath, sectionUid, categoryCode }) => {
        const { categories, navigationTrees, selectedNavigationTrees } = state

        const { selectedNavigationTrees: newSelectedNavigationTrees, navigationTrees: newNavigationTrees } = setNavigationTree(
            navigationTrees,
            selectedNavigationTrees,
            sectionUid,
            categoryPath,
        )

        const categoriesClone = cloneDeep(categories)
        categoriesClone[categoryCode].lastReference = new Date().getTime()

        return {
            ...state,
            lastUpdate: new Date(),
            categoryCode,
            categories: categoriesClone,
            navigationTrees: newNavigationTrees,
            selectedNavigationTrees: newSelectedNavigationTrees,
        }
    }

const setNavigationTree = (
    navigationTrees,
    selectedNavigationTrees: SelectedNavigationTree[],
    sectionUid: string,
    categoryPath: number[],
) => {
    const navigationTreesCopy = cloneDeep(navigationTrees)
    selectedNavigationTrees.forEach(({ sectionUid: selectedSectionUid, pathOne, pathTwo }) => {
        if (pathTwo !== undefined) {
            /**
             * Reset child navigation item
             */
            navigationTreesCopy[selectedSectionUid].node.items[pathOne].items[pathTwo].selected = false
        } else {
            /**
             * Reset parent navigation item
             */
            navigationTreesCopy[selectedSectionUid].node.items[pathOne].selected = false
        }
    })
    const newSelectedNavigationTrees = []
    const itemExists =
        navigationTrees[sectionUid] &&
        navigationTrees[sectionUid].node.items &&
        navigationTrees[sectionUid].node.items[categoryPath[1]]
    if (itemExists) {
        navigationTreesCopy[sectionUid].node.items[categoryPath[1]].expanded = true
        const isChildItem =
            navigationTrees[sectionUid].node.items[categoryPath[1]].items &&
            navigationTrees[sectionUid].node.items[categoryPath[1]].items[categoryPath[2]]
        if (isChildItem) {
            newSelectedNavigationTrees.push({
                sectionUid,
                pathOne: categoryPath[1],
                pathTwo: categoryPath[2],
            })
            navigationTreesCopy[sectionUid].node.items[categoryPath[1]].items[categoryPath[2]].selected = true
        } else {
            newSelectedNavigationTrees.push({
                sectionUid,
                pathOne: categoryPath[1],
            })
            navigationTreesCopy[sectionUid].node.items[categoryPath[1]].selected = true
        }
    }
    return {
        navigationTrees: navigationTreesCopy,
        selectedNavigationTrees: newSelectedNavigationTrees,
    }
}

export const reducer = (state = INITIAL_STATE, { payload, type }: Action): CategoriesStore => {
    switch (type) {
        case ActionTypes.SET_CATEGORY_DATA_IN_STORE:
            return parseCategoryData(state)(payload)
        case ActionTypes.CLEAR_CATEGORY_DATA: {
            const limit: number = new Date().getTime() - CATEGORY_CACHE_TTL_SECONDS * 1000 + 1000
            const categoryKeys: string[] = Object.keys(state.categories)
            const categories: Categories = cloneDeep(state.categories)
            categoryKeys.forEach((category: string) => {
                if (state.categoryCode !== category && categories[category] && categories[category].lastReference < limit) {
                    delete categories[category]
                }
            })
            return {
                ...state,
                categories,
            }
        }
        case ActionTypes.SET_FILTERS: {
            /**
             * There's likely only one product available,
             * therefore the min and max prices for the slider are the same.
             * Filter out those sliders since they are redundant.
             */
            const sliders = payload.sliders
                .filter((slider) => slider.rangeStart !== slider.rangeEnd)
                .map((slider) => ({
                    ...slider,
                    rangeStart: Math.floor(slider.rangeStart),
                    rangeEnd: Math.ceil(slider.rangeEnd),
                    scaleStart: Math.floor(slider.scaleStart),
                    scaleEnd: Math.ceil(slider.scaleEnd),
                }))
            return {
                ...state,
                allFilters: {
                    ...state.allFilters,
                    [payload.categoryCode]: {
                        facets: payload.facets,
                        sliders,
                    },
                },
            }
        }
        case ActionTypes.SET_LOADING:
            return {
                ...state,
                loading: payload.loading,
            }
        case ActionTypes.SET_APPENDING_PRODUCTS:
            return {
                ...state,
                appendingProducts: payload.appendingProducts,
            }
        case ActionTypes.SET_CATEGORY:
            return parseSetCategory(state)(payload)
        case ActionTypes.SET_SELECTED_FACETS:
            return {
                ...state,
                selectedFacets: {
                    ...state.selectedFacets,
                    [payload.categoryCode]: payload.selectedFacets,
                },
            }
        case ActionTypes.SET_USER_LIMITS:
            return {
                ...state,
                userLimits: {
                    ...state.userLimits,
                    [payload.categoryCode]: payload.userLimits,
                },
            }
        case ActionTypes.SET_FETCHING_FILTER_CODE:
            return {
                ...state,
                fetchingFilterCode: payload.filterCode,
            }
        case ActionTypes.SET_SORTING:
            return {
                ...state,
                sorting: payload.sorting,
            }
        case ActionTypes.TOGGLE_EXPANDED_NAVIGATIONTREE: {
            const navigationTreeIndexes: string[] = payload.treeIndex.split('_')
            const categorySectionId: string = payload.categorySectionId
            const navigationTrees: NavigationTrees = state.navigationTrees
            try {
                if (navigationTreeIndexes.length === 1) {
                    const expanded: boolean = navigationTrees[categorySectionId].node.items[navigationTreeIndexes[0]].expanded
                    navigationTrees[categorySectionId].node.items[navigationTreeIndexes[0]].expanded = !expanded
                } else {
                    const expanded: boolean =
                        navigationTrees[categorySectionId].node.items[navigationTreeIndexes[0]].items[navigationTreeIndexes[1]]
                            .expanded
                    navigationTrees[categorySectionId].node.items[navigationTreeIndexes[0]].items[
                        navigationTreeIndexes[1]
                    ].expanded = !expanded
                }
            } catch (e) {
                console.log(e)
            }
            return {
                ...state,
                navigationTrees,
            }
        }
        case ActionTypes.FETCHED_SUBCATEGORIES: {
            const { subcategories: subcategoriesPayload }: FetchedSubcategoriesPayload = payload
            const subcategories: any = {
                ...state.subcategories,
            }
            subcategoriesPayload?.forEach((subcategory: SubcategoryNode) => {
                subcategories[subcategory.code] = subcategory
            })
            return {
                ...state,
                subcategories,
            }
        }
        case ActionTypes.FETCHED_BRANDSLIST_CATEGORIES: {
            const { brands, categoryName, categoryCode, categoryMetaTitle, categoryMetaDescription }: BrandsListCategory = payload

            return {
                ...state,
                brandsListCategories: {
                    ...state.brandsListCategories,
                    [categoryCode]: {
                        brands: brands,
                        categoryName: categoryName,
                        categoryCode: categoryCode,
                        categoryMetaTitle: categoryMetaTitle,
                        categoryMetaDescription: categoryMetaDescription,
                    },
                },
            }
        }
        case ActionTypes.FETCHED_MODELSLIST_CATEGORIES: {
            const {
                models,
                categoryName,
                brandName,
                categoryCode,
                categoryMetaTitle,
                categoryMetaDescription,
                preamble,
            }: ModelsListCategory = payload

            return {
                ...state,
                modelsListCategories: {
                    ...state.modelsListCategories,
                    [categoryCode]: {
                        models,
                        brandName,
                        categoryName,
                        categoryCode,
                        categoryMetaTitle,
                        categoryMetaDescription,
                        preamble,
                    },
                },
            }
        }
        default:
            return state
    }
}
