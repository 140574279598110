export const NAME: string = 'DesktopHeader'

export type DesktopHeaderTheme = {
    headerComponents?: HeaderComponents[]
}

export enum HeaderComponents {
    SITE_LOGO = 'siteLogo',
    COUNTRY_SELECTOR = 'countrySelector',
    CUSTOMER_TYPE_SELECTOR = 'customerTypeSelector',
    PROMOTIONS = 'promotions',
    SEARCH = 'search',
    MY_ACCOUNT_AND_CART = 'myAccountAndCart',
}
