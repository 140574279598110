import { NavigationTreeNodeItems } from '@spa-core/store/categories/interfaces'
import Link from '@ui-elem-js/Link'
import { CollapsablePanel } from '@ui-elem/CollapsablePanel'
import classNames from 'classnames'
import React from 'react'

enum Style {
    MOBILE_FOOTER = 'mobile_footer',
    MOBILE_FOOTER_NODES = 'mobile_footer_nodes',
}

interface Props {
    footerNavigationNodes: NavigationTreeNodeItems[]
}

const FooterLinkForMobile = ({ footerNavigationNodes }: Props): React.ReactNode => {
    return (
        <div className={classNames(Style.MOBILE_FOOTER, 'w-full md:hidden px-1')}>
            {footerNavigationNodes ? (
                <ul className={classNames(Style.MOBILE_FOOTER_NODES, 'm-0')}>
                    {footerNavigationNodes.map((item: NavigationTreeNodeItems) => (
                        <React.Fragment key={item.uid + item.url}>
                            {item.nodes?.map((innerItem: NavigationTreeNodeItems, index) =>
                                innerItem.nodes?.length > 0 ? (
                                    <CollapsablePanel
                                        key={'accordionitem_' + innerItem.uid}
                                        title={innerItem.title}
                                        className={'pb-4'}
                                    >
                                        <li className="e2e-ver-footer-mobile-nodes pl-2 pt-4">
                                            <ul className="m-0">
                                                {/* Lists footer menu items */}
                                                {innerItem.nodes
                                                    .filter((link) => link.url)
                                                    .map((link) => (
                                                        <li key={link.url} className={'e2e-ver-url' + link.url.replace('/', '-')}>
                                                            <Link
                                                                to={link.url}
                                                                external={link.external}
                                                                target={link.target}
                                                                linkTitle={link.title}
                                                            />
                                                        </li>
                                                    ))}
                                            </ul>
                                        </li>
                                    </CollapsablePanel>
                                ) : (
                                    // Adds content driven items
                                    <li
                                        key={'accordionitem1_' + index}
                                        className="e2e-ver-footer-mobile-content-nodes flex justify-center text-center"
                                    >
                                        <ul className="m-0">
                                            {innerItem.description && innerItem.image ? (
                                                <li className="px-4">
                                                    <h4 className={'pb-2'}>{innerItem.title}</h4>
                                                    <img alt={innerItem.image.altText} src={innerItem.image.url} />
                                                    <div dangerouslySetInnerHTML={{ __html: innerItem.description }} />
                                                </li>
                                            ) : null}
                                            {!innerItem.image && innerItem.description ? (
                                                <li className="px-4">
                                                    <h4 className="pb-2">{innerItem.title}</h4>
                                                    <div dangerouslySetInnerHTML={{ __html: innerItem.description }} />
                                                </li>
                                            ) : null}
                                        </ul>
                                    </li>
                                ),
                            )}
                        </React.Fragment>
                    ))}
                </ul>
            ) : null}
        </div>
    )
}

export default FooterLinkForMobile
