import { call, put, select, takeLatest, takeLeading } from 'redux-saga/effects'
import { ActionTypes } from './constants'
import {
    AutocompleteResponse,
    AutocompleteSearchPayload,
    SaveSearchResultPayload,
    SearchPayload,
    SearchResult,
} from './interfaces'
import { SessionConfig } from '../app/interfaces'
import { selectSessionConfig } from '../utils'
import { Product, SlimProduct } from '../products/interfaces'
import net from '@spa-core-js/services/networkSvc'
import { ActionTypes as ProductActionTypes } from '../products/constants'
import { GoogleAnalyticsCategory, TrackingActionTypes, MatomoLevel } from '@spa-core/tracking/constants'
import { parseProduct, parseSlimProduct } from '../products/utils'
import { clearSearch as clearSearchAction } from './actions'
import logger from '@spa-core-js/services/logSvc'

const log = logger.getLogger('Search')

export function* search({ payload }: any) {
    const {
        page: pagePayload,
        text,
        pageSize,
        slim: slimPayload,
        query,
        supressLoading,
        clearSearch = true,
    }: SearchPayload = payload

    try {
        const page: string = pagePayload ? `&page=${pagePayload}` : ''
        const slim: string = slimPayload ? '&slim=true' : ''

        if (!supressLoading) {
            yield put({
                type: ActionTypes.SET_LOADING,
                payload: {
                    loading: true,
                },
            })
        }

        if (clearSearch) {
            yield put(clearSearchAction())
        }

        const sessionConfig: SessionConfig = yield select(selectSessionConfig)

        const result: SearchResult = yield call(() =>
            net.get(`${sessionConfig.urlPrefix}/rest/v2/search?text=${text}${page}&pageSize=${pageSize}${slim}`),
        )

        const resultProducts = result?.productSearchResult?.results

        if (resultProducts?.length > 0) {
            if (slim) {
                yield put({
                    type: ProductActionTypes.FETCHED_SLIM_PRODUCTS,
                    payload: {
                        slimProducts: resultProducts?.map((slimProduct) =>
                            parseSlimProduct(slimProduct, sessionConfig.themeResourcePath),
                        ),
                    },
                })
            } else {
                yield put({
                    type: ProductActionTypes.FETCHED_PRODUCTS,
                    payload: {
                        products: resultProducts?.map((product) => parseProduct(product, sessionConfig.themeResourcePath)),
                    },
                })
            }
        }

        const productSearchResultProductCodes: string[] = resultProducts?.map(({ code }: Product | SlimProduct) => code) || []
        const saveSearchResultPayload: SaveSearchResultPayload = {
            modelSearchResults: result?.modelSearchResult,
            productSearchResultProductCodes,
            pagination: result?.productSearchResult?.pagination,
            filters: result?.filters,
            searchText: text,
        }
        const saveActionType: string = clearSearch ? ActionTypes.SAVE_SEARCH_RESULT : ActionTypes.ADD_TO_SEARCH_RESULTS

        yield put({
            type: saveActionType,
            payload: saveSearchResultPayload,
        })

        yield put({
            type: TrackingActionTypes.SEARCH_RESULTS,
            payload: {
                matomoLevel: MatomoLevel.MEDIUM,
                gaCat: GoogleAnalyticsCategory.SEARCH_FULL_PAGE,
                gaLabel: text || query,
                searchResult: result,
                clear: clearSearch,
                term: text || query,
            },
        })

        yield put({
            type: ActionTypes.SET_LOADING,
            payload: {
                loading: false,
            },
        })
    } catch (_) {
        yield put(clearSearchAction())
        yield put({
            type: ActionTypes.SET_LOADING,
            payload: {
                loading: false,
            },
        })
    }
}

export function* autocompleteSearch({ payload }: any) {
    const sessionConfig: SessionConfig = yield select(selectSessionConfig)
    const { text }: AutocompleteSearchPayload = payload
    try {
        const result: AutocompleteResponse = yield call(() =>
            net.get(`${sessionConfig.urlPrefix}/search/autocompleteExtensive?term=${text}`),
        )
        yield put({
            type: ActionTypes.SAVE_AUTOCOMPLETE_SEARCH_RESULT,
            payload: {
                autocompleteProducts: result.products,
            },
        })
        yield put({
            type: TrackingActionTypes.AUTOCOMPLETE_SEARCH_RESULTS,
            payload: {
                matomoLevel: MatomoLevel.MEDIUM,
                gaLabel: text,
                gaCat: GoogleAnalyticsCategory.SEARCH_RESULT,
                term: text,
                result,
            },
        })
    } catch (err) {
        log.error('search autocomplete error', err)
    }
}

export const watchers = [takeLatest(ActionTypes.AUTOCOMPLETE_SEARCH, autocompleteSearch), takeLeading(ActionTypes.SEARCH, search)]
