/**
 * Created by knuts on 2016-06-14.
 */
module.exports = (path) => {
  const Paths = Java.type('java.nio.file.Paths'); // eslint-disable-line no-undef
  const Files = Java.type('java.nio.file.Files'); // eslint-disable-line no-undef
  const lines = Files.readAllLines(Paths.get(path), Java.type('java.nio.charset.StandardCharsets').UTF_8); // eslint-disable-line no-undef
  const data = [];
  lines.forEach((line) => {
    data.push(line);
  });
  return JSON.parse(data.join('\n'));
};
