import postalCodeFormatter from 'seco/localeSvc/postalCodeFormatter';
/**
 * Locales supported for address formatting. Supported locales are: da, sv, no, de, de_AT, de_CH, fr_CH, fr, fr_BE,
 * nl_BE, nl, fi, sv_FI, it, es, es_IE, en.
 */
const locales = {
  //   da: {
  //     fieldSequence: ['line1', 'line2', 'publicKey', 'postalcode', 'town', 'country'],
  //   },
  default: {
    fieldSequence: ['line1', 'line2', 'publicKey', 'postalcode', 'town', 'country'],
  },
};

/**
 * Class for formatting addresses. This should not be inlcude directly but used via the locale service.
 */
export class AddressFormatter {
  format(line1, line2, publicKey, postalcode, town, countryName, inputLocale) {
    let locale = inputLocale;
    if (locale === undefined) {
      locale = 'sv';
    }
    const filteredConf = locales[locale] ? locales[locale] : locales.default;
    const result = [];
    filteredConf.fieldSequence.forEach((e) => {
      if (e === 'line1' && line1) {
        result.push(line1);
      } else if (e === 'line2' && line2) {
        result.push(line2);
      } else if (e === 'publicKey' && publicKey) {
        result.push(formatPublicKey(publicKey));
      } else if (e === 'postalcode' && postalcode) {
        result.push(postalCodeFormatter.format(postalcode, locale));
      } else if (e === 'town' && town) {
        result.push(town);
      } else if (e === 'country' && countryName) {
        result.push(countryName);
      }
    });
    return result;
  }
}

const formatPublicKey = (p) => 'Portkod: ' + p;

const formatter = new AddressFormatter();
export default formatter;
