import React, { Fragment } from 'react'
import { useDispatch } from 'react-redux'
import Icon, { Icons } from '@ui-elem/Icon/Icon'
import classnames from 'classnames'
import { removeMessage } from '@spa-core/store/global-messages/actions'

type Props = {
    id: number | string
    level: string
    title: string
    message: React.ReactNode | string
}

const Message: React.FC<Props> = ({ id, level, title, message }) => {
    const dispatch = useDispatch()

    return (
        <Fragment key={id}>
            <div className="px-0 py-1 m-0">
                <div
                    id={`message_${id}`}
                    className={classnames(
                        'flex round-box items-center justify-between cursor-pointer mb-4',
                        `col-${level}`,
                        'round-box',
                    )}
                    key={id}
                    onClick={() => {
                        dispatch(removeMessage(id))
                    }}
                    // ref={(ref) => dispatch(setScrollToElement(ref))}
                    data-testid={`message_${id}`}
                >
                    <div className={'flex p-2'}>
                        <div className="title pr-2">{title}</div>
                        <div className="msg ic-padding-l">{message}</div>
                    </div>
                    <Icon icon={Icons.Times} className={'pr-1'} />
                </div>
            </div>
        </Fragment>
    )
}

export default Message
