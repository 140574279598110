export const NAME: string = 'OneBuyPanel'

export type OneBuyPanelTheme = {
    iconHeight?: string
    showCartIcon?: boolean
    innerDivJustification?: string
    showOneKrSticker?: boolean
    oneKrSticker?: {
        rootPath: string
        sticker: any
    }
}
