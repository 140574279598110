import { Icons } from '../constants'
import {
    FaAngleRight,
    FaSpinner,
    FaCheck,
    FaAngleDown,
    FaRegUser,
    FaUser,
    FaRegCompass,
    FaRegSquareCheck,
    FaRegSquare,
    FaSliders,
    FaShare,
    FaAngleLeft,
    FaEnvelope,
    FaBars,
    FaBullhorn,
    FaCaretUp,
    FaCaretDown,
    FaAngleUp,
    FaRecycle,
    FaUserLock,
    FaTruck,
    FaAddressCard,
    FaRegCreditCard,
    FaCartArrowDown,
    FaRegClock,
    FaPiggyBank,
    FaTriangleExclamation,
    FaPlus,
    FaMinus,
    FaCircleCheck,
    FaClock,
    FaRegCircleDot,
    FaRegCirclePause,
    FaRegCirclePlay,
    FaRegCircleStop,
    FaGlobe,
    FaPhone,
    FaHouse,
    FaUnlockKeyhole,
    FaDoorOpen,
    FaRegHeart,
    FaPencil,
    FaCircle,
} from 'react-icons/fa6'
import {
    FaRegSadTear,
    FaTimes,
    FaHome,
    FaAddressBook,
    FaEdit,
    FaSignOutAlt,
    FaSearch,
    FaCalendarAlt,
    FaHistory,
    FaMapMarkerAlt,
    FaTrash,
    FaTimesCircle,
    FaRegCircle,
    FaShoppingBasket,
    FaSyncAlt,
    FaRegCommentAlt,
    FaReply,
    FaUserCircle,
    FaQuoteRight,
} from 'react-icons/fa'
import { IoCartOutline, IoCart } from 'react-icons/io5'
import { GiVacuumCleaner } from 'react-icons/gi'
import { PiPrinterFill } from 'react-icons/pi'
import { HiOutlineMagnifyingGlassPlus } from 'react-icons/hi2'
import { IconStyles } from '../interfaces/IconStyles'
import React from 'react'
import { CiCircleInfo } from 'react-icons/ci'
import { GoDot, GoDotFill } from 'react-icons/go'
import { RiShoppingBasket2Fill, RiShoppingBasket2Line } from 'react-icons/ri'

export const getDefaultIcon = (iconName: Icons, styles: IconStyles): React.ReactNode => {
    switch (iconName) {
        case Icons.AngleRight:
            return <FaAngleRight style={styles} />
        case Icons.AngleLeft:
            return <FaAngleLeft style={styles} />
        case Icons.AngleDown:
            return <FaAngleDown style={styles} />
        case Icons.AngleUp:
            return <FaAngleUp style={styles} />
        case Icons.SadTear:
            return <FaRegSadTear style={styles} />
        case Icons.Spinner:
            return <FaSpinner style={styles} className={'animate-spin'} />
        case Icons.Times:
            return <FaTimes style={styles} />
        case Icons.Home:
            return <FaHome style={styles} />
        case Icons.AddressBook:
            return <FaAddressBook style={styles} />
        case Icons.Edit:
            return <FaEdit style={styles} />
        case Icons.Check:
            return <FaCheck style={styles} />
        case Icons.CartOutline:
            return <IoCartOutline style={styles} />
        case Icons.Cart:
            return <IoCart style={styles} />
        case Icons.RegUser:
            return <FaRegUser style={styles} />
        case Icons.User:
            return <FaUser style={styles} />
        case Icons.RegCompass:
            return <FaRegCompass style={styles} />
        case Icons.RegSquareCheck:
            return <FaRegSquareCheck style={styles} />
        case Icons.RegSquare:
            return <FaRegSquare style={styles} />
        case Icons.Sliders:
            return <FaSliders style={styles} />
        case Icons.Share:
            return <FaShare style={styles} />
        case Icons.SignOutAlt:
            return <FaSignOutAlt style={styles} />
        case Icons.Envelope:
            return <FaEnvelope style={styles} />
        case Icons.Bars:
            return <FaBars style={styles} />
        case Icons.Search:
            return <FaSearch style={styles} />
        case Icons.Bullhorn:
            return <FaBullhorn style={styles} />
        case Icons.CaretUp:
            return <FaCaretUp style={styles} />
        case Icons.CaretDown:
            return <FaCaretDown style={styles} />
        case Icons.Recycle:
            return <FaRecycle style={styles} />
        case Icons.UserLock:
            return <FaUserLock style={styles} />
        case Icons.Truck:
            return <FaTruck style={styles} />
        case Icons.AddressCard:
            return <FaAddressCard style={styles} />
        case Icons.CalendarAlt:
            return <FaCalendarAlt style={styles} />
        case Icons.RegCreditCard:
            return <FaRegCreditCard style={styles} />
        case Icons.CartArrowDown:
            return <FaCartArrowDown style={styles} />
        case Icons.History:
            return <FaHistory style={styles} />
        case Icons.VacuumCleaner:
            return <GiVacuumCleaner style={styles} />
        case Icons.PrinterFill:
            return <PiPrinterFill style={styles} />
        case Icons.RegClock:
            return <FaRegClock style={styles} />
        case Icons.MapMarkerAlt:
            return <FaMapMarkerAlt style={styles} />
        case Icons.PiggyBank:
            return <FaPiggyBank style={styles} />
        case Icons.MagnifyingGlassPlus:
            return <HiOutlineMagnifyingGlassPlus style={styles} />
        case Icons.TriangleExclamation:
            return <FaTriangleExclamation style={styles} />
        case Icons.Trash:
            return <FaTrash style={styles} />
        case Icons.Plus:
            return <FaPlus style={styles} />
        case Icons.Minus:
            return <FaMinus style={styles} />
        case Icons.CircleCheck:
            return <FaCircleCheck style={styles} />
        case Icons.TimesCircle:
            return <FaTimesCircle style={styles} />
        case Icons.Clock:
            return <FaClock style={styles} />
        case Icons.RegCircleDot:
            return <FaRegCircleDot style={styles} />
        case Icons.RegCircle:
            return <FaRegCircle style={styles} />
        case Icons.ShoppingBasket:
            return <FaShoppingBasket style={styles} />
        case Icons.RegCirclePause:
            return <FaRegCirclePause style={styles} />
        case Icons.RegCirclePlay:
            return <FaRegCirclePlay style={styles} />
        case Icons.RegCircleStop:
            return <FaRegCircleStop style={styles} />
        case Icons.SyncAlt:
            return <FaSyncAlt style={styles} />
        case Icons.Globe:
            return <FaGlobe style={styles} />
        case Icons.Phone:
            return <FaPhone style={styles} />
        case Icons.House:
            return <FaHouse style={styles} />
        case Icons.UserCircle:
            return <FaUserCircle style={styles} />
        case Icons.RegCommentAlt:
            return <FaRegCommentAlt style={styles} />
        case Icons.UnlockKeyhole:
            return <FaUnlockKeyhole style={styles} />
        case Icons.DoorOpen:
            return <FaDoorOpen style={styles} />
        case Icons.RegHeart:
            return <FaRegHeart style={styles} />
        case Icons.Pencil:
            return <FaPencil style={styles} />
        case Icons.Reply:
            return <FaReply style={styles} />
        case Icons.QuoteRight:
            return <FaQuoteRight style={styles} />
        case Icons.CircleInfo:
            return <CiCircleInfo style={styles} />
        case Icons.Dot:
            return <GoDot style={styles} />
        case Icons.DotFill:
            return <GoDotFill style={styles} />
        case Icons.Circle:
            return <FaCircle style={styles} />
        default:
            return <div />
    }
}

export const getDammsugarpasarIcon = (iconName: Icons, styles: IconStyles): React.ReactNode => {
    switch (iconName) {
        case Icons.Cart:
            return <RiShoppingBasket2Fill style={styles} />
        case Icons.CartOutline:
            return <RiShoppingBasket2Line style={styles} />
        default:
            return getDefaultIcon(iconName, styles)
    }
}

export const getLamportillalltIcon = (iconName: Icons, styles: IconStyles): React.ReactNode => {
    switch (iconName) {
        case Icons.Cart:
            return <IoCartOutline style={styles} />
        case Icons.CartOutline:
            return <IoCartOutline style={styles} />
        default:
            return getDefaultIcon(iconName, styles)
    }
}
