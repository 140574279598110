const palette = {
    patPink: '#e6057f',
    patPinkShade: '#b9006f',
    patPinkLight: '#e31e87',
    patGrey: '#e6e6e6',
    patYellowShade: '#fdd52c',
    patBlueShade: '#00618d',
    patBlueLight: '#0093d6',
    orange: '#e93e30',
    orangeHighlight: '#f05b49',
    blackGradient1: '#111',
    blackGradient2: '#222',
    blackGradient3: '#333',
    blackGradient4: '#525252',
    blackGradient6: '#666',
    blackGradient9: '#999',
    blackGradientb: '#b2b2b2',
    gray: '#cccecf',
    grayLight: '#e7e7e7',
    offWhite: '#f4f4f4',
    patronerBlueGradient: '#00618d',
    patronerLightBlue: '#d2eef9',
    patronerLightPeer: '#90c9e0',
    inkySkyBlueShadeLight: '#deedf9',
};
const theme = {
    siteId: 'patroner',
    palette,
    radius: '3px',
    col: {
        primary: {
            alt: 'white',
            fg: palette.patPink,
            peer: palette.patPinkShade,
        },
        secondary: {
            alt: 'white',
            fg: palette.patronerBlueGradient,
            peer: palette.patBlueShade,
        },
        tertiary: {
            alt: 'white',
            fg: '#343434',
            peer: palette.blackGradient4,
        },
        panel: {
            peer: palette.blackGradientb,
            fg: palette.patGrey,
            alt: palette.blackGradient3,
        },
        info: {
            alt: 'white',
            fg: '#6376ba',
            peer: '#5367aa',
        },
        error: {
            alt: '#f15c4a',
            fg: '#ffe5e6',
            peer: 'white',
        },
        pale: {
            alt: '#343434',
            fg: 'white',
            peer: palette.grayLight,
        },
        splash: {
            alt: '#343434',
            fg: palette.patronerLightBlue,
            peer: '#343434',
        },
        vars: {
            priceDiscountActive: palette.patBlueShade,
            bodyBg: palette.patGrey,
        },
    },
    skipColorClasses: true,
    font: {
        family: 'Arial, Helvetica, sans-serif',
        size: {
            // /** @option {string} font.size.x3 */
            // x3: '1.5rem',
            // /** @option {string} font.size.x2 */
            // x2: '1.8em',
            // /** @option {string} font.size.x1 */
            // x1: '18px',
            // /** This is the default font size
            //  * @option {string} font.size.m */
            // m: '1.15rem',
            // /** @option {string} font.size.s0 */
            // s0: '1.0em',
            // /** @option {string} font.size.s1 */
            // s1: '0.85em',
            // /** @option {string} font.size.s2 */
            // s2: '0.55em',
            /** @option {string} font.size.x4 */
            x4: '32px',
            /** @option {string} font.size.x3 */
            x3: '24px',
            /** @option {string} font.size.x2 */
            x2: '20px',
            /** @option {string} font.size.x1 */
            x1: '18px',
            /** This is the default font size
             * @option {string} font.size.m */
            m: '15.6px',
            /** @option {string} font.size.s0 */
            s1: '14px',
            /** @option {string} font.size.s1 */
            s2: '12px',
            /** @option {string} font.size.s2 */
            s3: '10px',
        },
    },
};
export default theme;
