import net from '@spa-core-js/services/networkSvc'
import { call, put, putResolve, select } from 'redux-saga/effects'
import { SessionConfig } from '../app/interfaces'
import { selectSessionConfig, trackAppliedPromotions } from '../utils'
import { ActionTypes } from './constants'
import { CampaignResponse, CampaignTrackingParam } from './interfaces'
import { TrackingActionTypes } from '../../tracking/constants'
import { navigateTo } from '../navigation/actions'
import urlSvc from '@spa-ec-js/services/urlSvc/urlSvc'
import { updateSessionConfig } from '../app/actions'
import { addToTrackingQueue } from '../../tracking/utils'
import cartEntriesToItem from '@spa-core-js/util/cartEntriesToItem'

export function* activateCampaign(campaignId: string) {
    const sessionConfig: SessionConfig = yield select(selectSessionConfig)
    const url: string = `${sessionConfig.urlPrefix}/rest/v1/campaign/activate/${campaignId}`

    try {
        yield put(updateSessionConfig())
        const {
            redirectUrl,
            campaignPageId,
            campaignNumber,
            campaignActivated,
            trackingParams,
            cartData,
            siteMapNodeDto,
        }: CampaignResponse = yield call(() => net.get(url))
        yield put({
            type: ActionTypes.CAMPAIGN_ACTIVATED,
            payload: {
                redirectUrl,
                campaignPageId,
                campaignNumber,
                campaignActivated,
                trackingParams,
            },
        })

        yield trackAppliedPromotions(cartData.appliedOrderPromotions, cartData.appliedProductPromotions)

        if (siteMapNodeDto) {
            yield put({
                type: 'NAV_SVC_SITEMAP_ADD_ENTRY',
                entry: siteMapNodeDto,
            })
        }

        const trackCampaignPayload: any = {
            campaignNumber,
        }
        trackingParams?.forEach(({ name, value }: CampaignTrackingParam) => {
            trackCampaignPayload[name] = value
        })

        if (redirectUrl) {
            /**
             * Add tracking to tracking queue before executing redirect.
             * This is an edge case because the redirect aborts the tracking.
             * All tracking should otherwise be done by dispatching tracking events
             * picked up by the tracking middleware.
             */
            if (cartData?.entries?.length) {
                yield putResolve(() =>
                    addToTrackingQueue({
                        event: 'view_cart',
                        currency: cartData?.totalIncVatPrice?.currencyIso,
                        value: cartData?.totalIncVatPrice?.value,
                        b2bStore: sessionConfig.b2bMode,
                        items: cartEntriesToItem(cartData.entries),
                    }),
                )
            }
            yield putResolve(() =>
                addToTrackingQueue({
                    event: 'activate_campaign',
                    promotion_id: trackCampaignPayload.id,
                    creative_name: trackCampaignPayload.utm_source,
                    creative_slot: trackCampaignPayload.utm_content,
                    campaign_id: trackCampaignPayload.campaignNumber,
                }),
            )
            const url: string = urlSvc.checkIfUrlIsAbsolute(redirectUrl)
                ? redirectUrl
                : `${sessionConfig.urlPrefix}${redirectUrl}`
            yield put(navigateTo({ url }))
        } else {
            /**
             * If no redirect is happening, dispatch tracking "as usual",
             * no need to manually queue them.
             */
            yield put({
                type: TrackingActionTypes.VIEW_CART_PAGE,
                payload: {
                    currency: cartData?.totalIncVatPrice?.currencyIso,
                    value: cartData?.totalIncVatPrice?.value,
                    entries: cartData.entries || [],
                },
            })
            yield put({
                type: TrackingActionTypes.CAMPAIGN_ACTIVATED,
                payload: trackCampaignPayload,
            })
        }
    } catch (err) {
        // Do nothing if there is an error in fetching breadcrumbs to avoid breaking of the saga
    }
}

export const watchers = []
