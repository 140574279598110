import React from 'react';
import { connect } from 'react-redux';
import cloneDeep from 'fast-clone';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { setupInitialState, mapItems, setupMutations } from 'core/util/module-utils';
import browserSvc from 'score/browserSvc';
import logger from 'score/logSvc';
// Setup module multi/single instance name etc
const multipleInstances = false;
const name = 'AppUtil';

// Modules data, this is the initial data for an instance
const initialState = {
  ID2: 'x',
  JSESSIONID: 'x',
};

const log = logger.getLogger(name);
const conf = { multipleInstances, name, initialState };

let history = null;

export const navigation = {
  navigateTo: (url) => {
    if (history && history.push && url && url.trim() !== '') history.push(url);
  },
  replaceTo: (url) => {
    if (history && history.replace && url && url.trim() !== '') history.replace(url);
  },
};

// ################# GETTERS  #################
const getters = (state, ownProps) => {
  // Leave this line fetches ta state variable depending on the module is using instances or not
  const instance = cloneDeep(mapItems(state, conf, ownProps.iid));

  return instance;
};

// ################# ACTIONS  #################
const actions = (dispatch, ownProps) => ({
  navigateTo: (url) => dispatch({ type: 'NAVIGATE_TO', url }),
  replaceTo: (url) => dispatch({ type: 'REPLACE_TO', url }),
  saveCookies: (ID2, JSESSIONID) => {
    dispatch({ type: 'APP_UTIL_SAVE_COOKIES', iid: ownProps.iid, ID2, JSESSIONID, noAnalytics: true });
  },
});

// ################# MUTATIONS  #################

const mutations = {
  NAVIGATE_TO: (_state, action) => {
    if (action.url && action.url.trim() !== '') {
      setTimeout(() => {
        navigation.navigateTo(action.url);
      }, 0);
    }
  },
  REPLACE_TO: (_state, action) => {
    if (action.url && action.url.trim() !== '') {
      setTimeout(() => {
        navigation.replaceTo(action.url);
      }, 0);
    }
  },
};

// ################# MODULE SETUP DON T TOUCH  #################
export const _module = {
  name,
  state: setupInitialState(initialState, conf),
  actions,
  mutations: setupMutations(mutations, conf),
};

class AppUtil extends React.Component {
  static get propTypes() {
    return {
      /** Determines if this AppUtil section is to be renedered in-line or as a block */
      history: PropTypes.shape({
        push: PropTypes.func.isRequired,
      }),
    };
  }
  componentDidMount() {
    history = this.props.history;
    const ID2 = browserSvc.cookieGet('ID2');
    const JSESSIONID = browserSvc.cookieGet('JSESSIONID');

    if (ID2 !== this.props.ID2 || JSESSIONID !== this.props.JSESSIONID) {
      // Updated cooke detected, clear state
      this.props.saveCookies(ID2, JSESSIONID);
    }
  }
  /**
   * Render function for react, called very time there is state change.
   * @returns {Html} The rendered code
   */
  render() {
    return null;
  }
}

require("core/redux/reducer").registerModule(name, _module);require("@spa-core/redux/store").newModuleLoaded();
export default withRouter(connect(getters, actions)(AppUtil));
