import cookie from 'core/util/ic-cookie';

const startDate = new Date();

function pad(n, len) {
  let s = n.toString();
  if (s.length < len) {
    s = ('0000000000' + s).slice(-len);
  }

  return s;
}

let cacheDisabled = false;

const formatTime = () => '%c' + pad(new Date().getTime() - startDate.getTime(), 5);

/**
 * Formats the Log Level and Name with Date
 * @param level: Log Level
 * @param name: Log Name
 * @returns Formatted String
 */
function format(level, name) {
  return `%c${level}:${name}: `;
}

/**
 * Function to get all the parameters attached in URL
 * @returns Json Array of url parameters
 */
function getUrlVars() {
  if (typeof window === 'undefined') {
    return {};
  }
  if (typeof window.location === 'undefined') {
    return {};
  }
  let hash;
  const vobj = {};
  if (window.location.href.indexOf('?') < 0) {
    return {};
  }
  const hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
  for (let i = 0; i < hashes.length; i++) {
    hash = hashes[i].split('=');
    vobj[hash[0]] = hash[1];
  }
  return vobj;
}

const noop = () => {
  // NOSONAR
}; // NOSONAR

const setLogFunctions = (logLevel, name, o) => {
  o.trace = noop;
  o.debug = noop;
  o.info = noop;
  o.warn = noop;
  o.raw = noop;
  o.error = noop;

  // Check env if no setTimeout exists we are in nashorn
  if (typeof setTimeout === 'undefined') {
    // In nashorn
    setServerLogFunctions('trace', o);
    return;
  }
  // Check if the browser is TOO old, bail out
  if (typeof window === 'undefined') {
    return;
  }

  // Check if the browser is TOO old, bail out
  if (typeof window.location === 'undefined') {
    return;
  }

  // Log handling for oldre browsers without bind, not as fancy capabilities but will log, arguments to logging (,)
  // will suffer but the rest will be logged
  if (typeof window.console.log.bind === 'undefined') {
    // Using NOSONAR becease we want to use the functinality of the switch statememts without the
    // break statememsts here

    setLegacyLogFunctions(logLevel, o);

    return;
  }

  setModernLogFunctions(logLevel, name, o);
};

/**
 * Function for setting up logger for logging on the server side.
 * @param logLevel
 */
const setServerLogFunctions = (logLevel, o) => {
  const LocalLogger = Java.type('org.apache.log4j.Logger'); // eslint-disable-line no-undef
  const LOG = LocalLogger.getLogger('React');
  // Using NOSONAR becease we want to use the functinality of the switch statememts without the
  // break statememsts here

  switch (logLevel) {
    case 'trace': // NOSONAR
      o.trace = (str) => {
        LOG.trace(str);
      };
    case 'debug': // NOSONAR
      o.debug = (str) => {
        LOG.debug(str);
      };
    case 'info': // NOSONAR
      o.info = (str) => {
        LOG.info(str);
      };
    case 'warn': // NOSONAR
      o.warn = (str) => {
        LOG.warn(str);
      };
    case 'error': // NOSONAR
      o.error = (str) => {
        LOG.error(str);
      };
    default: // NOSONAR
  }
};

/**
 * Function for setting up logger for with an old web browser.
 * @param logLevel
 */
const setLegacyLogFunctions = (logLevel, o) => {
  switch (logLevel) {
    case 'trace': // NOSONAR
        o.trace = console.log; // eslint-disable-line
    case 'debug': // NOSONAR
        o.debug = console.log; // eslint-disable-line
    case 'info': // NOSONAR
        o.info = console.log; // eslint-disable-line
    case 'warn': // NOSONAR
        o.warn = console.log; // eslint-disable-line
    case 'error': // NOSONAR
        o.error = console.log; // eslint-disable-line

    default: // NOSONAR
  }
};

/**
 * Function for setting up logger for with an modern web browser.
 * @param logLevel
 * @param name
 */
const setModernLogFunctions = (logLevel, name, o) => {
  // Using NOSONAR becease we want to use the functinality of the switch statememts without the
  // break statememsts here

  switch (logLevel) {
    case 'trace': // NOSONAR
      o.trace = window.console.log.bind(window.console, format('TRACE', name));
    case 'debug': {
      // NOSONAR

      o.debug = window.console.debug.bind(window.console);
      const orgDebug = o.debug;

      o.debug = Function.prototype.bind.call(
        orgDebug,
        o,
        formatTime() + format(' DEBUG', name),
        'background:green;color: white;',
        'color: green;'
      );
    }
    case 'info': {
      // NOSONAR

      o.info = window.console.info.bind(window.console);
      const orgInfo = o.info;

      o.info = Function.prototype.bind.apply(
        orgInfo,
        o,
        format(' INFO', name),
        'background:lightblue;color: white;',
        'color: lightblue;'
      );
    }
    case 'warn': {
      // NOSONAR

      o.warn = window.console.warn.bind(window.console);

      o.raw = window.console.warn;
      const orgWarn = o.warn;

      o.warn = Function.prototype.bind.call(
        orgWarn,
        o,
        formatTime() + format(' INFO', name),
        'background:orange;color: white;',
        'color: orange;'
      );
    }
    case 'error': // NOSONAR
      o.error = window.console.error.bind(window.console, format('ERROR', name), 'color:grey;', '');
    default: // NOSONAR
  }
};

/**
 * Function that calculates what log level to use based on url, cookies etc.
 * @returns {string}
 */
const getLogLevel = () => {
  // Check loglevel
  const vars = getUrlVars();
  let logLevel = 'error';
  if (vars.loglevel) {
    cookie.set('loglevel', vars.loglevel);
    logLevel = vars.loglevel;
    cookie.set('no-cache', 1);
  } else {
    const levelFromCookie = cookie.get('loglevel');
    if (levelFromCookie) {
      logLevel = levelFromCookie;
    } else {
      const noCache = cookie.get('no-cache');
      if (noCache) {
        logLevel = 'info';
      }
    }
  }

  if (vars['no-cache'] || vars.nocache) {
    cacheDisabled = true;
  }

  return logLevel;
};

const resetLogLevel = (level, o) => {
  setLogFunctions(level, o.name, o);
};

const loggers = [];
let theLogger = {
  trace: noop,
  debug: noop,
  info: noop,
  warn: noop,
  error: noop,
};

const service = {
  createLogger(name) {
    const level = getLogLevel();
    const logger = {
      trace: noop,
      debug: noop,
      info: noop,
      warn: noop,
      error: noop,
      stamp: () => ['%c' + pad(new Date().getTime() - startDate.getTime(), 5), 'background: purple; color: white'],
      name,
    };

    setLogFunctions(level, name, logger);
    loggers.push(logger);
    return logger;
  },

  getLogger() {
    return theLogger;
  },

  getUrlVars() {
    return getUrlVars();
  },
  getLogLevel() {
    return getLogLevel();
  },
  setLogLevel(level) {
    cookie.set('loglevel', level, { path: '/' });
    loggers.forEach((logger) => resetLogLevel(level, logger));
  },
  cacheDisabled() {
    return cacheDisabled;
  },
};

theLogger = service.createLogger('L');

export default service;
