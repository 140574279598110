import format from 'format-number-with-string';
/**
 * Locales supported for currency formatting. Supported locales are: da, sv, no, de, de_AT, de_CH, fr_CH, fr, fr_BE,
 * nl_BE, nl, fi, sv_FI, it, es, es_IE, en.
 */
const locales = {
  da: {
    longFormat: '#.###.###,#0',
    shortFormat: '#.###.###,#0',
    decFormat: '#.###.###,#0',
    noCurrency: '#.###.###,#0',
  },
  sv: {
    longFormat: '# ### ### kr',
    shortFormat: '# ### ###:(minus)',
    decFormat: '# ### ###,#0 kr',
    noCurrency: '# ### ###,#0',
  },
  no: {
    longFormat: '# ### ###(comma)(minus)',
    shortFormat: '# ### ###(comma)(minus)',
    decFormat: '# ### ###,#0 kr',
    noCurrency: '#.###.###,#0',
  },
  de: {
    longFormat: '#.###.###,#0 €',
    shortFormat: '#.###.###,#0',
    decFormat: '#.###.###,#0 €',
    noCurrency: '#.###.###,#0',
  },
  de_AT: {
    longFormat: '#.###.###,#0 €',
    shortFormat: '#.###.###,#0',
    decFormat: '#.###.###,#0 €',
    noCurrency: '#.###.###,#0',
  },
  de_CH: {
    longFormat: '#,###,###.#0 CHF',
    shortFormat: '#,###,###.#0',
    decFormat: '#,###,###.#0 CHF',
    noCurrency: '#,###,###.#0',
  },
  fr_CH: {
    longFormat: '#,###,###,###.00 CHF',
    shortFormat: '#,###,###,###.00',
    decFormat: '#,###,###,###.#0 CHF',
    noCurrency: '#,###,###,###.#0',
  },
  fr: {
    longFormat: '# ### ###,#0 €',
    shortFormat: '# ### ###,#0',
    decFormat: '# ### ###,#0 €',
    noCurrency: '# ### ###,#0',
  },
  fr_BE: {
    longFormat: '#.###.###,#0 €',
    shortFormat: '#.###.###,#0',
    decFormat: '#.###.###,#0 €',
    noCurrency: '#.###.###,#0',
  },
  nl_BE: {
    longFormat: '#.###.###,#0 €',
    shortFormat: '#.###.###,#0',
    decFormat: '#.###.###,#0 €',
    noCurrency: '#.###.###,#0',
  },
  nl: {
    longFormat: '€ #.###.###,#0',
    shortFormat: '#.###.###,#0',
    decFormat: '#.###.###,#0',
    noCurrency: '#.###.###,#0',
  },
  fi: {
    longFormat: '# ### ###,#0€',
    shortFormat: '# ### ###,#0',
    decFormat: '# ### ###,#0€',
    noCurrency: '#.###.###,#0',
  },
  sv_FI: {
    longFormat: '# ### ###,#0€',
    shortFormat: '# ### ###,#0',
    decFormat: '# ### ###,#0€',
    noCurrency: '#.###.###,#0',
  },
  it: {
    longFormat: 'EUR #.###.###,#0',
    shortFormat: '#.###.###,#0',
    decFormat: '#.###.###,#0',
    noCurrency: '#.###.###,#0',
  },
  es: {
    longFormat: 'EUR #.###.###,#0',
    shortFormat: '#.###.###,#0',
    decFormat: '#.###.###,#0',
    noCurrency: '#.###.###,#0',
  },
  en_IE: {
    longFormat: '€#,###,###.#0',
    shortFormat: '#,###,###.#0',
    decFormat: '#,###,###.#0',
    noCurrency: '#,###,###.#0',
  },
  en: {
    longFormat: '£#,###,###.#0',
    shortFormat: '#,###,###.#0',
    decFormat: '#,###,###.#0',
    noCurrency: '#,###,###.#0',
  },
};

/**
 * Handler for cases not supported by the numbering formater library:
 * * Minus as a part of the currency symbol, minus is a character with formatting semantics
 * * , as a part of the currency symbol, comma is a character with formatting semantics
 * * ' as thousands delimiter
 * @param str
 * @param locale
 */
const handleCornerCases = function handleCornerCases(str, locale) {
  let res = str.replace('(minus)', '-').replace('(comma)', ',');
  if (locale.indexOf('_CH') >= 0) {
    res = res.replace(/,/g, '\''); // eslint-disable-line
  }
  return res;
};

/**
 * Class for formatting currencies. This should not be inlcude directly but used via the locale service.
 */
export class PriceFormatter {
  long(price, inputLocale) {
    let locale = inputLocale;
    if (locale === undefined) {
      locale = 'sv';
    }
    const res = format(price, locales[locale].longFormat);
    return handleCornerCases(res, locale);
  }

  short(price, locale) {
    const res = format(price, locales[locale].shortFormat);
    return handleCornerCases(res, locale);
  }

  noCurrency(price, locale) {
    const res = format(price, locales[locale].noCurrency);
    return handleCornerCases(res, locale);
  }

  dec(price, locale) {
    if (locales[locale].decFormat) {
      const res = format(price, locales[locale].decFormat);
      return handleCornerCases(res, locale);
    }
    const res = format(price, locales[locale].shortFormat);
    return handleCornerCases(res, locale);
  }
}

const formatter = new PriceFormatter();
export default formatter;
